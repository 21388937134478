// this has been suppressed as there isn't enough information in the NavAid to justify a 
// page on its own. The tile is sufficient

import React from 'react'
import {useLocation} from 'react-router-dom'
import {Link } from 'react-router-dom'

function NavAidView() {

    const location = useLocation()

    return <div>
        <div>
            NavAid View Page
        </div>
        <address>
            {location.state.name}<br/>
            {location.state.city}<br/>
            {location.state.state_code}<br/>
        </address>
        <Link to="/">Back to map</Link>
    </div>

}

export default NavAidView