import React, { useState, useEffect, useContext } from 'react'
import UserContext from '../../../store/user-context'
import { FaStar } from 'react-icons/fa'
import axios from 'axios'

const StarRating = props => {
    const userCtx = useContext(UserContext)

    const [rating, setRating] = useState(props.place.rating_average)
    const [hover, setHover] = useState(null)

    useEffect(() => {
        setRating(props.place.rating_average)

        // TODO this doesn't belong here - needs to be moved to placeview
        if (rating > 0 && props.place.vote_count > 0) {
            const script = document.createElement('script')

            script.setAttribute('type', 'application/ld+json')
            script.textContent = `{
            "@context":  "http://schema.org",
            "type": "LocalBusiness",
            "name": "${props.place.name}",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": ${Math.round(rating * 10) / 10},
                "reviewCount": ${props.place.vote_count}
            },
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "${props.place.city}",
                "addressRegion": "${props.place.state_code}",
                "postalCode": "${props.place.postal_code}",
                "streetAddress": "${props.place.address}"
            },          
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": ${props.place.latitude},
                "longitude": ${props.place.longitude}
            }
        }`

            script.async = true
            document.body.appendChild(script)

            return () => {
                document.body.removeChild(script)
            }
        }
    }, [props])

    const handlePost = stars => {
        setRating(stars)

        const rate = { stars: stars, place_id: props.place.id }

        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
        $('#ajax-loader').show()

        axios
            .post('/api/v1/rates', rate)

            .then(resp => {
                $('#ajax-loader').hide()
                alert('Thank you: your rating was added')
            })
            .catch(resp => {
                console.log('fallito! ', resp)
            })
    }
    return (
        <div className="text-center">
            {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1

                const color =
                    ratingValue <= (hover || rating)
                        ? '#ffc107' // yellow
                        : !hover && ratingValue == Math.ceil(rating)
                        ? rating - Math.floor(rating) > 0.5
                            ? // fractional value
                              '#fbda75' // darker yellow
                            : '#fff3cd' // light yellow
                        : '#e4e5e9' // gray

                return (
                    <label>
                        {userCtx.loggedIn && (
                            <input
                                className="star"
                                type="radio"
                                name="rating"
                                value={ratingValue}
                                // onClick={() => setRating(ratingValue)}
                                onClick={() => handlePost(ratingValue)}
                            />
                        )}
                        {userCtx.loggedIn ? (
                            <FaStar
                                className="star"
                                color={color}
                                onMouseEnter={() => setHover(ratingValue)}
                                onMouseLeave={() => setHover(null)}
                            />
                        ) : (
                            <FaStar className="star" color={color} />
                        )}
                    </label>
                )
            })}
            {props.place.vote_count > 2 && (
                <span className="small-text">
                    <span className="left-spacer">
                        <span>{Math.round(rating * 10) / 10}</span>
                    </span>
                    {/* {' ('}
                    <span >{props.place.vote_count}</span>
                    {' votes)'} */}
                </span>
            )}
        </div>
    )
}

export default StarRating
