import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { ImgTag } from '../src/icons_lib'
import countryList from 'react-select-country-list'
import { strToDate, Place_atts, vesselThumbnail } from '../src/helpers'
import { placeThumbnail } from '../src/helpers'
import UserContext from '../store/user-context'

const UserView = () => {
    const [user, setUser] = useState()
    const location = useLocation()
    const userCtx = useContext(UserContext)

    useEffect(() => {
        if (location.state) {
            // internal react request, already includes vessel object
            setUser(location.state)
        } else {
            // external request, need to retrieve vessel from API
            const pathname = location.pathname
            const user_id = pathname.split('/').slice(-1)[0]
            const url_str = `/api/v1/users/${user_id}`
            axios
                .get(url_str)
                .then(resp => {
                    setUser(resp.data)
                    console.log('user: ', resp.data)
                })
                .catch(resp => console.log('Error in retrieving user', resp))
        }
    }, [])

    const vesselItems = list => {
        return list.map((item, index) => {
            return (
                <li key={index} className="list-group-item">
                    <div className="row">
                        <div className="col-2">
                            <img
                                className="img-thumbnail"
                                src={vesselThumbnail(item)}
                                alt={item.name}
                            />
                        </div>
                        <div className="col">
                            <Link to={`/vesselview/${item.id}`}>
                                {item.name}
                            </Link>
                        </div>
                        <div className="col">{item.vessel_type}</div>
                    </div>
                </li>
            )
        })
    }
    const placeItems = list => {
        return list.map((item, index) => {
            return (
                <li key={index} className="list-group-item">
                    <div className="row">
                        <div className="col-2">
                            <img
                                width="60px"
                                className="img-thumbnail"
                                src={placeThumbnail(item)}
                                alt={item.id}
                            />
                        </div>
                        <div className="col">
                            <Link to={`/placeview/${item.id}`}>
                                {item.name}
                            </Link>{' '}
                        </div>
                        <div className="col">
                            {Place_atts[item.type_enum].name}
                        </div>
                    </div>
                </li>
            )
        })
    }

    return userCtx.loggedIn && user ? (
        <>
            <div className="row">
                <h5 className='m-3'>User profile: {user && user.name}</h5>
                <div className="row">
                    <div className="col">
                        <ul className="list-group shadow">
                            <li className="list-group-item text">
                                <div className="col">
                                    <strong>{user.name}</strong>
                                </div>
                            </li>
                            <li className="list-group-item">{user.cty}</li>
                            <li className="list-group-item">
                                {user.country_code &&
                                    countryList().getLabel(user.country_code)}
                            </li>
                            <li className="list-group-item">
                                Joined: {strToDate(user.created_at)}
                            </li>
                        </ul>
                    </div>
                    <div className="col border shadow rounded p-3 m-3">
                        {user.thumb_url ? (
                            <img
                                src={`/${user.thumb_url}`}
                                alt={`squiddio_user_${user.id}`}
                            />
                        ) : (
                            <ImgTag icon_name="person" size={60} />
                        )}
                    </div>
                </div>
            </div>

            <h5 className="mt-5">User's vessels</h5>
            <ul className="list-group shadow">{vesselItems(user.vessels)}</ul>
            <h5 className="mt-5">User's places</h5>
            <ul className="list-group shadow">{placeItems(user.places)}</ul>
        </>
    ) : (
        <div className="row ps-100">
            <div className="m-100">You must be logged in to view this page</div>
        </div>
    )
}

export default UserView
