import React, { useState, Fragment } from 'react'
import axios from 'axios'
import { toLonLat, fromLonLat, transform } from 'ol/proj'
import { PlaceTypeOptions } from '../../src/helpers'
import CCLicense from '../CCLicense'

const PlaceForm = props => {
    const [place, setPlace] = useState({type_enum: 0})
    const [done, setDone] = useState(false)

    const clickedCoords = props.clicked_coords
    const lonLat = toLonLat(clickedCoords)
    const lng = lonLat[0]
    const lat = lonLat[1]

    const handleChange = e => {
        e.preventDefault()
        setPlace(Object.assign({}, place, { [e.target.name]: e.target.value }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        place['longitude'] = lng
        place['latitude'] = lat

        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
        console.log('confirm submit new poi')
        if (confirm('Submit new POI? (May take a few seconds....)') == true) {
            $('#ajax-loader').show() // TODO can't get this to show
            axios
                .post('/api/v1/places', { place })
                .then(resp => {
                    // TODO there must be a better way to reset the values to null in the state
                    setPlace({ name: '', city: '', description: '' })
                    props.callBack()
                    setDone(true)
                    $('#ajax-loader').hide()
                })
                .catch(resp => {})
        }
    }

    if (done) {
        return (
            <>
                <h5>POI added</h5>
                <p className="small-text overlay-medium p-2">
                    Thank you! As the original creator of this POI, you can edit or delete it at any time by clicking on its icon. Note: the POI may
                    be deleted by site admin if it doesn't meet guidelines
                </p>
            </>
        )
    } else {
        return (
            <div className="p-2 overlay-large">
                <h6 className="mt-2"> Enter new place information:</h6>
                <div>Latitude: {Math.round(lat * 1000) / 1000}</div>
                <div>Longitude: {Math.round(lng * 1000) / 1000}</div>
                <form onSubmit={handleSubmit} className="p-3">
                    <div className="form-floating mb-3">
                        <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder="e.g. Snug Cove"
                            onChange={handleChange}
                            required
                        />
                        <label for="floatingInput">Give it a name:</label>
                    </div>

                    <div className="form-floating mb-3">
                        <select
                            className="form-control"
                            type="select"
                            name="type_enum"
                            onChange={handleChange}
                        >
                            <PlaceTypeOptions />
                        </select>
                        <label for="floatingSelect">Select a POI type:</label>
                    </div>

                    <div className="form-floating mb-3">
                        <textarea
                            className="form-control"
                            type="text"
                            name="description"
                            onChange={handleChange}
                            placeholder="Quiet, protected anchorage with good holding. Exposed to the SE"
                            style={{ height: '100px' }}
                        />
                        <label for="floatingTextArea">
                            A brief description:
                        </label>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary btn-sm mt-1"
                    >
                        Submit for review
                    </button>
                </form>
                <CCLicense />
            </div>
        )
    }
}

export default PlaceForm
