import React, { useEffect, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import UserContext from '../../store/user-context'
import { VesselTypeOptions, CountryOptions, delFields } from '../../src/helpers'
import CCLicense from '../CCLicense'

const VesselForm = props => {
    const userCtx = useContext(UserContext)

    // set up field values in form
    const { register, handleSubmit } = useForm({
        defaultValues: props.vessel,
    })

    const [vessel, setVessel] = useState(props.vessel)

    const handleChange = e => {
        e.preventDefault()
        setVessel(
            Object.assign({}, vessel, { [e.target.name]: e.target.value })
        )
    }
    const handleCancel = () => {
        props.callBack(null)
    }

    const onSubmit = vessel => {
        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

        delete vessel.country_name
        vessel.user_id = userCtx.user.id

        delFields(['user_first_name', 'user_last_name', 'user_name'], vessel)

        if (props.action == 'post') {
            axios
                // new vessel
                .post('/api/v1/vessels', { vessel })
                .then(resp => {
                    setVessel({ ...vessel, id: resp.data.id })
                    props.callBack({ ...vessel, id: resp.data.id })
                    alert(`Vessel ${vessel.name} was added to your profile`)
                })
                .catch(resp => {
                    console.log('Error in adding vessel')
                })
        } else {
            // claiming existing MMSI vessel
            axios
                .patch(`/api/v1/vessels/${vessel.id}`, { vessel })
                .then(resp => {
                    props.callBack({ ...vessel, id: resp.data.id })
                    alert(`Vessel ${vessel.name} was added to your profile`)
                })
                .catch(resp => {
                    console.log('Error in adding vessel')
                })
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-field">
                    <label className="text-muted">MMSI</label>
                    <input
                        {...register('mmsi')}
                        className="form-control"
                        type="text"
                        name="mmsi"
                        //placeholder="123123123123"
                        onChange={handleChange}
                        // do not allow changing the MMSI. Only allow adding it when creating new vessel and it's missing
                        disabled={
                            vessel.mmsi || (props.addVessel && !vessel.mmsi)
                        }
                    />
                </div>
                <div className="form-field">
                    <label className="text-muted">Vessel name</label>
                    <input
                        {...register('name')}
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="e.g. Sloop John B."
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-field">
                    <label className="text-muted">Vessel type</label>
                    <select
                        {...register('vessel_type')}
                        className="form-control"
                        type="select"
                        name="vessel_type"
                        defaultValue="Sailboat"
                        onChange={handleChange}
                    >
                        <VesselTypeOptions />
                    </select>
                </div>
                <div className="form-field">
                    <label className="text-muted">
                        Country of registration
                    </label>
                    <select
                        {...register('country_registration')}
                        className="form-control"
                        type="select"
                        name="country_registration"
                        onChange={handleChange}
                    >
                        <CountryOptions />
                    </select>
                </div>
                <div className="form-field">
                    <label className="text-muted">Length</label>
                    <input
                        {...register('loa')}
                        className="form-control"
                        type="number"
                        step="any"
                        name="loa"
                        placeholder="e.g. 12"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-field">
                    <label className="text-muted">Make</label>
                    <input
                        {...register('make')}
                        className="form-control"
                        type="text"
                        name="make"
                        placeholder="e.g. Beneteau"
                        onChange={handleChange}
                    />
                </div>
                <div className="form-field">
                    <label className="text-muted">Model</label>
                    <input
                        {...register('model')}
                        className="form-control"
                        type="text"
                        name="model"
                        placeholder="e.g. 43 Oceanis"
                        onChange={handleChange}
                    />
                </div>
                <div className="form-field">
                    <label className="text-muted">Description</label>
                    <input
                        {...register('description')}
                        className="form-control"
                        type="text"
                        name="description"
                        placeholder="e.g. Well fitted, fully equipped, ocean going vessel. Two cabin, deep keel version."
                    />
                </div>
                <div className="row justify-content-center mt-2">
                    <div className="col-4 text-center">
                        <button type="submit" className="btn btn-secondary">
                            Add Vessel
                        </button>
                    </div>
                    <div className="col-3">
                        <button
                            type="submit"
                            className="btn btn-light"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
            <CCLicense />
        </>
    )
}

export default VesselForm
