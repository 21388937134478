import React, { useState, useRef, useEffect } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import XYZ from 'ol/source/XYZ'
import Feature from 'ol/Feature'
import { Point } from 'ol/geom'
import { fromLonLat, transform } from 'ol/proj'
import { defaults as defaultInteractions } from 'ol/interaction'
import { poisStyle } from '../../src/ol_layers'
import countryList from 'react-select-country-list'
import { strToDate } from '../../src/helpers'

const VesselPosition = props => {
    const mapElement = useRef()

    const vessel = props.vessel
    const lngLat = vessel.last_position && vessel.last_position.coordinates

    const [map, setMap] = useState()
    //const mapRef = useRef()
    //mapRef.current = map

    const setUpMap = () => {
        // create map
        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                // USGS Topo
                new TileLayer({
                    source: new XYZ({
                        url:
                            ' https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    }),
                }),
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: fromLonLat(lngLat),
                zoom: 14,
            }),
            controls: [],
        })

        const item = new Feature({
            geometry: new Point(fromLonLat(lngLat)),
            class: 'connection',
            type: vessel.last_position.type,
        })

        var poiLayer = new VectorLayer({
            source: new VectorSource({
                features: [item],
            }),
            visible: true,
            zindex: 1,
            style: poisStyle,
            name: 'PlaceLayer',
            title: 'PlaceLayer',
        })

        initialMap.addLayer(poiLayer)

        // set map onclick handler
        initialMap.on('click', handleMapClick)
        
        // save map to state
        setMap(initialMap)
    }

    useEffect(() => {
        if (vessel.last_position && !map) {
            setUpMap()
        }
    }, [vessel])

    const handleMapClick = event => {
        const coords = event.coordinate
        const lngLatAr = transform(coords, 'EPSG:3857', 'EPSG:4326')
        alert(` ${lngLatAr[0]} ${lngLatAr[1]}`)
    }

    return (
        <div className="p-3">
            <h5>Last known position</h5>
            {lngLat && (
                <div className="">
                    <div>
                        Latitude: {Math.round(lngLat[1] * 1000) / 1000}{' '}
                        <span className="ms-1" />
                        Longitude: {Math.round(lngLat[0] * 1000) / 1000}
                        <span className="ms-5" />
                        Reported on: { strToDate(vessel.last_position.created_at) }
                    </div>
                    {vessel.last_position.nearby_place && (
                        <div>
                            Near:
                            <span className="ms-3" />
                            {vessel.last_position.nearby_place.city}
                            <span className="ms-3" />
                            {vessel.last_position.nearby_place.state_code}
                            <span className="ms-3" />
                            {countryList().getLabel(
                                vessel.last_position.nearby_place.country_code
                            )}
                        </div>
                    )}
                </div>
            )}
            <div ref={mapElement} className="last-position"></div>
        </div>
    )
}

export default VesselPosition
