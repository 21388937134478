// react
import React, { useState, useEffect, useRef, Fragment, useContext } from 'react'

// openlayers
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import Overlay from 'ol/Overlay'
import VectorSource from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON'
import { defaults as defaultInteractions } from 'ol/interaction'

import { toLonLat, fromLonLat, transform } from 'ol/proj'
import {
    displayLayer,
    show_layers,
    layerUrl,
    get_layer,
} from '../../src/ol_layers'

// components
import PlaceTile from '../Place/PlaceTile'
import NavAidTile from '../NavAid/NavAidTile'
import MeteoRepTile from '../MeteoRep/MeteoRepTile'
import ConnectionTile from '../Connection/ConnectionTile'
import PlaceForm from '../Place/PlaceForm'
import ContextMenu from './ContextMenu'
import UserContext from '../../store/user-context'
import Selector from '../Selector'

function MapWrapper(props) {
    const userCtx = useContext(UserContext)
    const MinZoom = 8

    // ---------------------------------------------------------------- set intial state
    const [map, setMap] = useState()
    const [placeId, setPlaceId] = useState()
    const [navAidId, setNavAidId] = useState()
    const [meteoRep, setMeteoRep] = useState()
    const [connection, setConnection] = useState()
    const [clickedCoords, setClickedCoords] = useState([])
    const [contextEvent, setContextEvent] = useState()
    const [addPoi, setAddPoi] = useState(false)
    // set default zoom value. Increased when user gives position
    const initialZoom = userCtx.lastZoom === 0 ? MinZoom : userCtx.lastZoom
    const [zoom, setZoom] = useState(initialZoom)
    const [mapCorners, setMapCorners] = useState([])
    const [coordAvail, setCoordAvail] = useState(false)
    const [tileZoom, setTileZoom] = useState(15)

    // create state refs that can be used in callbacks to access current values
    // Updated state values are available in render (return stmts) and effects, but not callback functions!
    //  https://stackoverflow.com/a/60643670
    const mapElement = useRef()
    const overlayElement = useRef()
    const contextElement = useRef()
    const mapRef = useRef()
    mapRef.current = map
    const refLayers = useRef()
    refLayers.current = userCtx.activeLayers
    const zoomRef = useRef(zoom)
    const tileZoomRef = useRef(tileZoom)

    // -------------------------------------------------------------- effects
    // get the user position upon initial render
    useEffect(() => {
        // if the last coordinates of the current sessions are not available, and they are not the
        // default starting coordinates, get user's position from browser
        if (userCtx.lastCoords.default) {
            navigator.geolocation.getCurrentPosition(
                posAvailable,
                posUnavailable,
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                }
            )
        }
    }, [])

    // initialize map once user coordinates are known, or when layer selector changes
    useEffect(() => {
        // remove previous map if it exists (i.e. when the user position effect triggers a new map after user shares position)
        $('.ol-viewport').remove()

        // create map
        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url:
                            ' https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                        // url:
                        //     'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
                        // attributions: ['', '<b>Not For Navigation</b> Powered by ESRI. Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, the GIS and sQuidd.io User Community'],
                        attributions: [
                            '',
                            '<b>Not For Navigation</b> Powered by ESRI',
                        ],
                        attributionsCollapsible: true,
                    }),
                }),
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: fromLonLat([
                    userCtx.lastCoords.longitude,
                    userCtx.lastCoords.latitude,
                ]),
                zoom: zoom,
                maxZoom: 18,
                minZoom: 3,
            }),
            //controls: [], // need to keep this off or it will hide attributions
            interactions: defaultInteractions({
                altShiftDragRotate: false,
                pinchRotate: false,
            }),
        })

        // set map onclick handler
        initialMap.on('click', handleMapClick)

        // set map onChange handler
        initialMap.on('moveend', handleMapChange)

        // context menu http://jsfiddle.net/ddan/eno1x5hk/
        initialMap.getViewport().addEventListener('contextmenu', function(e) {
            e.preventDefault()
            setContextEvent(e)
        })

        // save map to state
        setMap(initialMap)
    }, [coordAvail, userCtx.activeLayers])

    // hide hi-res layers when zoomed out
    useEffect(() => {
        if (map) {
            show_layers(map, 'NavAidLayer',  zoom > MinZoom)            
            show_layers(map, 'AisPathLayer', zoom > MinZoom)
        }
    }, [zoom])

    //---------------------------------------------------------- helper functions
    const posAvailable = pos => {
        const crd = pos.coords
        setZoom(10)
        updCtxView(crd.longitude, crd.latitude)
        setCoordAvail(true)
    }

    const posUnavailable = err => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
        setCoordAvail(true)
    }

    const updCtxView = (lng, lat) => {
        userCtx.updView({ longitude: lng, latitude: lat, default: false }, 10)
    }

    function updCtxFeatures(features) {
        userCtx.updFeatures(features)
    }
    function clearTiles() {
        setPlaceId()
        setMeteoRep()
        setNavAidId()
        setConnection()
    }
    // --------------------------------------------------------- event handler functions
    // map click handler
    const handleMapClick = event => {
        // turn context menu off if left open
        if (contextElement) {
            setContextEvent()
        }

        const overlayConatinerElement = overlayElement.current

        const overlayLayer = new Overlay({
            element: overlayConatinerElement,
        })
        mapRef.current.addOverlay(overlayLayer)

        mapRef.current.forEachFeatureAtPixel(event.pixel, function(
            feature,
            layer
        ) {
            const coords = event.coordinate

            overlayLayer.setPosition(coords)

            let id = feature.get('id')

            if (layer.values_.title === 'PlaceLayer') {
                if (feature.values_.count == null) {
                    clearTiles()
                    setPlaceId(id)
                } else {
                    overlayConatinerElement.innerHTML =
                        'Zoom in to see local POIs'
                }
            }
            if (layer.values_.title === 'NavAidLayer') {
                clearTiles()
                setNavAidId(id)
            }
            if (layer.values_.title === 'MeteoLayer') {
                //let obs = feature.get('observations')
                clearTiles()
                setMeteoRep(feature.values_)
            }
            if (layer.values_.title === 'VesselLayer') {
                //let obs = feature.get('observations')
                clearTiles()
                setConnection(feature.values_)
            }

            overlayLayer.setPosition(coords)
            setAddPoi(false)
        })
    }

    // map drag/zoom handler
    const handleMapChange = event => {
        // set up variables for layers
        const mapZoom = event.frameState.viewState.zoom
        const ctrCoord = toLonLat(event.frameState.viewState.center)

        updCtxView(ctrCoord[0], ctrCoord[1], mapZoom)

        const vp_extent = event.frameState.extent
        const corners = [
            transform(vp_extent.slice(0, 2), 'EPSG:3857', 'EPSG:4326'),
            transform(vp_extent.slice(2, 4), 'EPSG:3857', 'EPSG:4326'),
        ]
        setMapCorners(corners)
        setZoom(mapZoom)
        zoomRef.current = mapZoom

        // activate layers based on current selections
        if (refLayers.current.pois) {
            const poisLayer = displayLayer(
                mapRef.current,
                zoomRef.current,
                corners,
                'PlaceLayer',
                `api/v1/places/?type=centroid&stinkpots=${refLayers.current.stinkPots}`,
                8,
                updCtxFeatures
            )
        }

        if (refLayers.current.satMaps) {
            displayLayer(
                mapRef.current,
                zoomRef.current,
                corners,
                'OutlineLayer',
                `api/v1/places/?type=outline&tile_zoom=${tileZoomRef.current}`,
                7,
                null
            )
        }
        if (refLayers.current.navAids && zoom > MinZoom) {
            displayLayer(
                mapRef.current,
                zoomRef.current,
                corners,
                'NavAidLayer',
                'api/v1/nav_aids/?',
                4,
                null
            )
        }
        if (refLayers.current.buoys && zoom > MinZoom) {
            displayLayer(
                mapRef.current,
                zoomRef.current,
                corners,
                'MeteoLayer',
                'api/v1/meteo_reps/?',
                5,
                null
            )
        }
        if (refLayers.current.vessels ) {
            displayLayer(
                mapRef.current,
                zoomRef.current,
                corners,
                'VesselLayer',
                'api/v1/connections/?',
                9,
                null
            )
        }
        if (refLayers.current.aisPaths && zoom > MinZoom) {
            displayLayer(
                mapRef.current,
                zoomRef.current,
                corners,
                'AisPathLayer',
                'api/v1/ais_paths/?',
                9,
                null
            )
        }
    }

    // ---------------------------------------------------------- external callbacks
    // from the context menu
    const handlePoiAdd = coords => {
        const overlayConatinerElement = overlayElement.current
        const overlayLayer = new Overlay({
            element: overlayConatinerElement,
        })
        mapRef.current.addOverlay(overlayLayer)
        overlayLayer.setPosition(coords)

        // set addPoi state to trigger the render.
        setAddPoi(true)
        setClickedCoords(coords)
        setPlaceId()
        setNavAidId()
        setMeteoRep()
    }

    const handleTileZoom = newZoomLevel => {
        // updates the OutlineLayer to a new tileZoom when called from ContextMenu
        setTileZoom(newZoomLevel)
        tileZoomRef.current = newZoomLevel
        const url = layerUrl(
            `api/v1/places/?type=outline&tile_zoom=${tileZoomRef.current}`,
            mapCorners,
            zoom
        )
        const l = get_layer(mapRef.current, 'OutlineLayer')
        const s = new VectorSource({ url: url, format: new GeoJSON() })
        l.setSource(s)
    }

    // from PlaceForm
    const refreshMap = (source, url) => {
        console.log('refresh all active layers....')
        let layers = map.getLayers()
        let length = layers.getLength()
        let l, s
        for (let i = 0; i < length; i++) {
            l = layers.item(i)
            s = l.getSource()
            s.refresh()
        }
    }

    // --------------------------------------------------------- render component
    return (
        <Fragment>
            <div ref={mapElement} className="map">
                <div ref={contextElement}>
                    {contextEvent && (
                        <ContextMenu
                            mapref={mapRef.current}
                            event={contextEvent}
                            zoom={zoom}
                            tileZoom={tileZoom}
                            callBackPoi={handlePoiAdd}
                            callBackZoom={handleTileZoom}
                            mapCorners={mapCorners}
                        ></ContextMenu>
                    )}
                </div>
                <div ref={overlayElement} className="overlay-div">
                    {placeId && (
                        <PlaceTile
                            place_id={placeId}
                            callBack={refreshMap}
                        ></PlaceTile>
                    )}
                    {navAidId && (
                        <NavAidTile
                            nav_aid_id={navAidId}
                            callBack={refreshMap}
                        ></NavAidTile>
                    )}
                    {meteoRep && (
                        <MeteoRepTile
                            // meteo_rep_id={meteoRepId}
                            meteo_rep={meteoRep}
                            callBack={refreshMap}
                        ></MeteoRepTile>
                    )}
                    {connection && (
                        <ConnectionTile
                            // meteo_rep_id={meteoRepId}
                            connection={connection}
                            callBack={refreshMap}
                        ></ConnectionTile>
                    )}
                    {addPoi && (
                        <PlaceForm
                            clicked_coords={clickedCoords}
                            callBack={refreshMap}
                        />
                    )}
                </div>
                <Selector />
            </div>
        </Fragment>
    )
}

export default MapWrapper
