import React, { useState, useRef, useEffect } from 'react'
import countryList from 'react-select-country-list'
import { strToDate, userThumbnail } from '../../../src/helpers'
import { Link } from 'react-router-dom'
import { LinkTt } from '../../../src/helpers'
import { addComma } from '../../../src/helpers'

const DataSheet = props => {
    // console.log('props.tags ', props.place.tags)

    const placeRef = useRef()
    placeRef.current = props.place

    const parseTags = () => {
        let tags
        // props.place is not available within function. Had to use useRef
        // also tried setting a state to no avail
        // see also https://codesandbox.io/s/setstate-vs-setref-5ej1u4
        if (placeRef.current.tags) {
            tags = placeRef.current.tags
            delete tags.sq_data
            let retArr = []
            // map through an object (instead of array)
            Object.keys(tags).forEach((key, index) => {
                retArr.push(<li key={index}>{[key, ':  ', tags[key]]}</li>)
            })
            return retArr
        }
    }
    return (
        <div className="row">
            <div className="col">
                <h6 className="bg-secondary text-white p-1">
                    {props.place.name}
                </h6>
                <address>
                    {props.place.address}
                    <br />
                    {addComma(props.place.city)} {addComma(props.place.state_code, props.place.postal_code)}{' '}
                    {props.place.postal_code}
                    <br />
                    {props.place.country_code &&
                        countryList().getLabel(props.place.country_code)}
                    <br />
                </address>
                <div>
                    Latitude: {Math.round(props.place.latitude * 100) / 100} -
                    Longitude: {Math.round(props.place.longitude * 100) / 100}
                </div>
                <div>sQuiddio id: {props.place.id}</div>
            </div>
            <div className="col">
                <h6 className="bg-secondary text-white p-1">
                    Custom Attributes
                </h6>
                {props.place.email && <div>Email: {props.place.email}</div>}
                {props.place.phone && <div>Phone: {props.place.phone}</div>}
                {props.place.url && <div>url: {props.place.url}</div>}

                <div>
                    Traffic index
                    <LinkTt
                        title="A measure of this POIs level of traffic and popularity (1: quiet, 5: very busy)"
                        id="t-1"
                        link="/faq#traffic_index"
                    />
                    : {props.place.popularity}
                </div>
                {/* <div>Rating average: {props.place.rating_average}</div> */}
                <ul>{parseTags()}</ul>
            </div>
            {props.place.user_name && (
                <div className="col">
                    <h6 className="bg-secondary text-white p-1">
                        Created by:{' '}
                    </h6>
                    <div>
                        <img
                            className="img-thumbnail me-1"
                            width="30px"
                            src={userThumbnail(props.place.user_thumb_url)}
                            alt="squiddio-user"
                        />
                        <Link to={`/userview/${props.place.user_id}`}>
                            {props.place.user_name}{' '}
                        </Link>{' '}
                    </div>
                    <div>{props.place.user_city}</div>
                    <div>
                        {props.place.user_country_code && countryList().getLabel(props.place.user_country_code)}
                    </div>
                    <div className="mt-1">
                        On:{' '}
                        {props.place.created_at &&
                            strToDate(props.place.created_at)}{' '}
                    </div>
                </div>
            )}
            {/* <div>{JSON.stringify(props.place.tags)}</div> */}
        </div>
    )
}

export default DataSheet
