import React from 'react'

const IntroModal = () => {
    return (
        <div
            className="modal fade"
            id="intro-modal"
            tabIndex="-1"
            aria-labelledby="modal-title"
            aria-hidden="false"
        >
            {/* <div className="modal-dialog modal-lg"> */}
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header text-bg-success">
                        <h4 className="modal-title text-white" id="modal-title">
                            Turbo-charge your navigation software!
                        </h4>
                        <button
                            type="button"
                            className="btn-close filter-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-text muted">
                                    <em>
                                        Breathe new life into your Chart Plotter
                                        thanks to always-on connectivity at sea with sQuiddio.
                                        Download spatial data layers of
                                        incredibly powerful and exciting
                                        navigation-related data, and view them
                                        directly on your chart.
                                    </em>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <img
                                src="/assets/mkt/rumcay_banner.jpg"
                                className="card-img-top"
                                alt="..."
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Download thousands of Points of Interests
                                </h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Right into your nav charts!
                                </h6>
                                <p className="card-text text-muted">
                                    Over 30,000 navigational Points of Interest
                                    from all over the world, courtesy of the
                                    ocean-going community of sQuiddio users!
                                </p>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <img
                                src="/assets/mkt/gcharts_banner.png"
                                className="card-img-top"
                                alt="..."
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Download Satellite Maps
                                </h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    For every POI in the database!
                                </h6>
                                <p className="card-text text-muted">
                                    View detailed satellite maps of harbors,
                                    marinas or anchorages overlayed on your nav
                                    charts.{'  '}
                                    <a
                                        href="/faq#satellite_maps"
                                        className="card-link"
                                    >
                                        more...
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <img
                                src="/assets/mkt/meteo_banner.png"
                                className="card-img-top"
                                alt="..."
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Up-to-the minute wind, wave and weather data
                                    near you
                                </h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    From buoys, ships and other sQuiddio users
                                </h6>
                                <p className="card-text text-muted">
                                    Temperature, pressure, wind speed and
                                    direction, wave height and period, wind
                                    gust, sea surface temperature and more from
                                    thousands of buoys, weather reporting ships
                                    and squiddio users from around the world.
                                    {'  '}
                                    <a
                                        href="/faq#weather_beacons"
                                        className="card-link"
                                    >
                                        more...
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <img
                                src="/assets/mkt/follow_banner.png"
                                className="card-img-top"
                                alt="..."
                            />
                            <div className="card-body">
                                <h5 className="card-title">
                                    Follow other vessels
                                </h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Right on your chart
                                </h6>
                                <p className="card-text text-muted">
                                    Track up-to-date position and on-board
                                    sensor (NMEA) information from vessels in
                                    your follow list.
                                    <a
                                        href="/faq#vessel_tracking"
                                        className="card-link"
                                    >
                                        more...
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <div className="card-body">
                                <h5 className="card-title">How?</h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    Download the sQuidd.io OpenCPN plugin or
                                    export a layer from our online map
                                </h6>
                                <p className="card-text text-muted">
                                    If you use OpenCPN as your navigation
                                    software, simply download and install the{' '}
                                    <a
                                        href="/squiddio_pi"
                                        className="card-link"
                                    >
                                        squidd.io plugin
                                    </a>
                                    . Alternatively, you can export your layers
                                    of interest for a specific area in GeoJSON
                                    or XML formats by right-clicking on the map
                                    in our home page.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroModal
