import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { CountryOptions } from '../../src/helpers'

const UserEdit = props => {
    const [user, setUser] = useState({})

    useEffect(() => {
        if (!props.user.country_code) {
            // initialize country code based on IP address
            axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                props.user.country_code = data.country_code
            }).catch((error) => {
                console.log(error);
            });
        }
        setUser(props.user)
    }, [props])

    const handleChange = e => {
        e.preventDefault()
        setUser(Object.assign({}, user, { [e.target.name]: e.target.value }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
        const user_id = user.id
        delete user.id
        axios
            .patch(`/api/v1/users/${user_id}`, { user })
            .then(resp => {
                alert('user profile updated')
            })
            .catch(resp => {})
    }


    return (
        <form onSubmit={handleSubmit}>
            <div className="form-field" onChange={handleChange}>
                <input
                    // {...register('first_name')}
                    className="form-control"
                    type="text"
                    name="first_name"
                    placeholder="e.g. John"
                    value={user.first_name}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-field">
                <input
                    // {...register('first_name')}
                    className="form-control"
                    type="text"
                    name="last_name"
                    placeholder="e.g. Calvin"
                    value={user.last_name}
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-field">
                <input
                    className="form-control"
                    type="text"
                    name="address"
                    placeholder="e.g. 54 Bridgeway Ave."
                    value={user.address}
                    onChange={handleChange}
                />
            </div>
            <div className="form-field">
                <input
                    className="form-control"
                    type="text"
                    name="city"
                    placeholder="e.g. Road Town"
                    value={user.city}
                    onChange={handleChange}
                />
            </div>
            <div className="form-field">
                <input
                    className="form-control"
                    type="text"
                    name="state_code"
                    placeholder="e.g. CA"
                    value={user.state_code}
                    onChange={handleChange}
                />
            </div>
            <div className="form-field">
                <input
                    className="form-control"
                    type="text"
                    name="postal_code"
                    placeholder="e.g. 94810"
                    value={user.postal_code}
                    onChange={handleChange}
                />
            </div>
            <div className="form-field">
                <select
                    className="form-control"
                    type="select"
                    name="country_code"
                    value={user.country_code}
                    onChange={handleChange}
                >
                    <CountryOptions />
                </select>
            </div>
            <div className="form-field">
                <input
                    className="form-control"
                    type="phone"
                    name="mobile_phone"
                    placeholder="e.g. +14807621987"
                    value={user.mobile_phone}
                    onChange={handleChange}
                />
            </div>
            <div className="form-field">
                <label htmlFor="" className="form-label me-2">
                    Your API key:
                </label>
                <input
                    className="form-control"
                    type="text"
                    name="apy_key"
                    value={user.api_key}
                    disabled={true}
                />
            </div>
            <div className="row justify-content-center">
                <div className="col-md-4 text-center">
                    <button type="submit" className="btn btn-primary m-3">
                        Update user profile
                    </button>
                </div>
            </div>
        </form>
    )
}

export default UserEdit
