import React, { useState, useEffect } from 'react'
import { ImgTag } from '../../src/icons_lib'
import axios from 'axios'

const UserPhoto = props => {
    const [file, setFile] = useState(null)
    const [photo, setPhoto] = useState('')

    useEffect(() => {
        setPhoto(props.user.thumb_url)
    }, [props.user])

    const handleSubmit = e => {
        e.preventDefault()
        // formData includes the multipart parameters to upload a file
        const photo = new FormData()
        photo.append('user[photo][image]', file)

        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

        axios
            .patch(`/api/v1/users/${props.user.id}`, photo)
            .then(resp => {
                //TODO can't get this to clear file in the form using the Value property on the field, as I do for comment
                setFile(null)
                const new_thumb_url = resp.data.new_thumb_url
                setPhoto(new_thumb_url)
            })
            .catch(resp => {
                console.log('fallito! ', resp)
            })
    }

    const handleDelete = url => {
        const urlSplit = decodeURIComponent(url).split('/')
        const photo_id = urlSplit[urlSplit.length - 3]

        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

        if (confirm('Confirm photo delete?') == true) {
            const url_str = `/api/v1/photos/${photo_id}`
            axios
                .delete(url_str)
                .then(resp => {
                    setPhoto(null)
                })
                .catch(resp => console.log('Error in deleting photo', resp))
        } else {
            console.log('photo delete canceled ')
        }
    }

    return (
        <div>
            <div className="img img-thumbnail">
                {photo ? (
                    <div>
                        <img src={'/' + photo} alt={props.user.first_name} />
                        <button
                            className="btn btn-sm"
                            onClick={() => {
                                handleDelete(photo)
                            }}
                        >
                            <ImgTag icon_name="trash3" size={15} />
                        </button>
                    </div>
                ) : (
                    <ImgTag icon_name="person" size={60} />
                )}

                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="field m-2">
                            <label htmlFor="" className="form-label me-2">
                                Select an image to upload:
                            </label>
                            <input
                                className="form-control"
                                type="file"
                                onChange={e => {
                                    setFile(e.target.files[0])
                                }}
                                required
                            />
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4 text-center">
                                <button
                                    className="btn btn-secondary m-2"
                                    type="submit"
                                >
                                    Update profile photo
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UserPhoto
