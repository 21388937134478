import React, { useState, useEffect } from 'react'
import {
    Place_atts,
    placeThumbnail,
    postThumbnail,
    truncate_with_dots,
} from '../../src/helpers'
import { ImgTag } from '../../src/icons_lib'
import { Link } from 'react-router-dom'
import axios from 'axios'

const Events = () => {
    const [events, setEvents] = useState([])

    useEffect(() => {
        axios
            .get(`/api/v1/home`)
            .then(resp => {
                setEvents(resp.data)
            })
            .catch(resp =>
                console.log(
                    'Error in retrieving events from home controller',
                    resp
                )
            )
    }, [])

    return (
        <>
            <h5>Recent Activity</h5>
            <ul className="list-group">
                {events.map((evt, index) => {
                    if (evt.type == 'Checkin') {
                        return (
                            <li className="list-group-item" key={index}>
                                <div className="row">
                                    <div className="col-2">{evt.when}</div>
                                    <div className="col-1">
                                        <ImgTag
                                            icon_name="checkin_sm"
                                            class_name="img-thumbnail"
                                            size={30}
                                        />
                                    </div>
                                    <div className="col-8">
                                        <Link
                                            to={`/vesselview/${evt.vessel_id}`}
                                        >
                                            {evt.vessel_name}
                                        </Link>{' '}
                                        checked in at:{' '}
                                        <Link to={`/placeview/${evt.poi_id}`}>
                                            {evt.place_name}
                                        </Link>
                                    </div>
                                </div>
                            </li>
                        )
                    } else if (evt.type == 'Post') {
                        return (
                            <li className="list-group-item" key={index}>
                                <div className="row">
                                    <div className="col-2">{evt.when}</div>
                                    <div className="col-1">
                                        {console.log("thumb url", evt.thumb_url) }
                                        <img
                                            width="40px"
                                            className="img-thumbnail"
                                            src={postThumbnail(evt)}
                                            alt={evt.place_name}
                                        />
                                    </div>
                                    {evt.provider == 'Blogger' ? (
                                        // squiddio social media post
                                        <div className="col-5">
                                            sQuidd.io posted on{' '}
                                            <Link
                                                to={evt.link}
                                            >
                                                {evt.provider}:
                                            </Link>
                                        </div>
                                    ) : (
                                        // user post in POI feed
                                        <div className="col-5">
                                            <Link
                                                to={`/userview/${evt.user_id}`}
                                            >
                                                {evt.user_name}
                                            </Link>{' '}
                                            posted on{' '}
                                            <Link
                                                to={`/placeview/${evt.poi_id}`}
                                            >
                                                {evt.place_name}
                                            </Link>
                                            :
                                        </div>
                                    )}

                                    <div className="col-4">
                                        {truncate_with_dots(evt.comment, 40)}
                                    </div>
                                </div>
                            </li>
                        )
                    } else if (evt.type == 'Place') {
                        return (
                            <li className="list-group-item" key={index}>
                                <div className="row">
                                    <div className="col-2">{evt.when}</div>
                                    <div className="col-1">
                                        <img
                                            width="40px"
                                            className="img-thumbnail"
                                            src={placeThumbnail(evt)}
                                            alt={evt.name}
                                        />
                                    </div>
                                    <div className="col-7">
                                        A new {Place_atts[evt.type_enum].name}{' '}
                                        was added:{' '}
                                        <Link to={`/placeview/${evt.id}`}>
                                            {evt.name}
                                        </Link>
                                    </div>
                                </div>
                            </li>
                        )
                    } else if (evt.provider == 'blogger') {
                        return (
                            <li className="list-group-item" key={index}>
                                <div className="row">
                                    <div className="col-2">{evt.when}</div>
                                    <div className="col-1">
                                        <img
                                            width="40px"
                                            className="img-thumbnail"
                                            src={postThumbnail(evt.thumb_url)}
                                            alt={evt.place_name}
                                        />
                                    </div>
                                    <div className="col-5">
                                        <Link to={`/userview/${evt.user_id}`}>
                                            {evt.user_name}
                                        </Link>{' '}
                                        posted on{' '}
                                        <Link to={evt.link}>blog</Link>:
                                    </div>
                                    <div className="col-4">
                                        {truncate_with_dots(evt.comment, 20)}
                                    </div>
                                </div>
                            </li>
                        )
                    } else {
                    }
                })}
            </ul>
        </>
    )
}
export default Events
