import consumer from "./consumer"

function ws_subscribe(room) {
    //consumer.subscriptions.create({ channel: "AisChannel", room: room });
    //console.log('ws_subscribe')
}

//ws_subscribe('hello_world');

export default ws_subscribe()


