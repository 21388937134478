import React from 'react'
import { Place_atts, Vessel_status } from '../../src/helpers'
import { iconPath } from '../../src/icons_lib'

const Legend = () => {
    return (
        <div className="accordion sm-accordion rounded" id="legend">
            <div className="accordion-item">
                <div className="accordion-header">
                    <button
                        className="small-text accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#legend-pulldown"
                        aria-expanded="true"
                        aria-controls="legend-pulldown"
                    >
                        Right-click on map for options. Click here to open
                        legend
                    </button>
                </div>

                <div
                    id="legend-pulldown"
                    className="accordion-collapse collapse p-3 row"
                    data-bs-parent="#legend"
                >
                    <div className="col small-text">
                        POI Types
                        <ul className="list-group">
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 19].map(
                                (key, index) => {
                                    return (
                                        <li
                                            className="list-group-item p-1"
                                            key={index}
                                        >
                                            {
                                                <div className="row">
                                                    <div className="col-1">
                                                        <img
                                                            width={15}
                                                            src={iconPath(
                                                                Place_atts[key]
                                                                    .icon
                                                            )}
                                                            alt={Place_atts[key]
                                                                .icon}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        {Place_atts[key].name}
                                                    </div>
                                                </div>
                                            }
                                        </li>
                                    )
                                }
                            )}
                        </ul>
                    </div>
                    <div className="col small-text">
                        Aids to Navigation
                        <ul className="list-group">
                            {[9, 12, 13].map((key, index) => {
                                return (
                                    <li
                                        className="list-group-item p-1"
                                        key={index}
                                    >
                                        {
                                            <div className="row">
                                                <div className="col-1">
                                                    <img
                                                        width={15}
                                                        src={iconPath(
                                                            Place_atts[key].icon
                                                        )}
                                                        alt={
                                                            Place_atts[key].icon
                                                        }
                                                    />
                                                </div>
                                                <div className="col">
                                                    {Place_atts[key].name}
                                                </div>
                                            </div>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                        Vessels
                        <ul className="list-group">
                            {[0, 1, 2].map((key, index) => {
                                return (
                                    <li
                                        className="list-group-item p-1"
                                        key={index}
                                    >
                                        {
                                            <div className="row">
                                                <div className="col-1">
                                                    <img
                                                        width={15}
                                                        src={iconPath(
                                                            Vessel_status[key]
                                                                .icon
                                                        )}
                                                        alt={
                                                            Vessel_status[key]
                                                                .icon
                                                        }
                                                    />
                                                </div>
                                                <div className="col">
                                                    {Vessel_status[key].name}
                                                </div>
                                            </div>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                        Vessel types
                        <ul className="list-group">
                            <li className="list-group-item p-1" key={1}>
                                {
                                    <div className="row">
                                        <div className="col-1">
                                            <img
                                                width={15}
                                                src={iconPath('sailboat_grn')}
                                                alt="sailboat_grn"
                                            />
                                        </div>
                                        <div className="col">Sailboat</div>
                                    </div>
                                }
                            </li>
                            <li className="list-group-item p-1" key={2}>
                                {
                                    <div className="row">
                                        <div className="col-1">
                                            <img
                                                width={15}
                                                src={iconPath('powerb_blu')}
                                                alt="powerb_blu"
                                            />
                                        </div>
                                        <div className="col">
                                            Pleasure Power Boat
                                        </div>
                                    </div>
                                }
                            </li>
                            <li className="list-group-item p-1" key={2}>
                                {
                                    <div className="row">
                                        <div className="col-1">
                                            <img
                                                width={15}
                                                src={iconPath('powerb_red')}
                                                alt="powerb_red"
                                            />
                                        </div>
                                        <div className="col">
                                            Superyacht, Large Cruise Ship
                                        </div>
                                    </div>
                                }
                            </li>
                            <li className="list-group-item p-1" key={2}>
                                {
                                    <div className="row">
                                        <div className="col-1">
                                            <img
                                                width={15}
                                                src={iconPath('powerb_yel')}
                                                alt="powerb_yel"
                                            />
                                        </div>
                                        <div className="col">
                                            Commercial Vessel (Ferry, Cargo,
                                            Tanker etc.)
                                        </div>
                                    </div>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Legend
