import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import XYZ from 'ol/source/XYZ'
import Feature from 'ol/Feature'
import { Point } from 'ol/geom'
import { defaults as defaultInteractions } from 'ol/interaction'

import { toLonLat, fromLonLat, transform } from 'ol/proj'
import { Link } from 'react-router-dom'
import { get_layer, poisStyle } from '../src/ol_layers'
import axios from 'axios'
import { CountryOptions, PlaceTypeOptions, delFields } from '../src/helpers'
import CCLicense from '../components/CCLicense'

function PlaceEdit() {
    const location = useLocation()
    //const place = location.state
    const [place, setPlace] = useState(location.state.place)
    //const [map, setMap] = useState()
    const [lngLat, setLngLat] = useState([place.longitude, place.latitude])
    const mapElement = useRef()
    //const mapRef = useRef()
    //mapRef.current = map

    const place_id = location.state.id
    let item
    const navigate = useNavigate()

    useEffect(() => {
        $('.ol-viewport').remove()

        // create map
        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                // USGS Topo
                new TileLayer({
                    source: new XYZ({
                        // url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
                        url:
                            ' https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    }),
                }),
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: fromLonLat(lngLat),
                zoom: 14,
                maxZoom: 16,
                minZoom: 14,
            }),
            interactions: defaultInteractions({ dragPan: false }),
            controls: [],
        })

        item = new Feature({
            geometry: new Point(fromLonLat(lngLat)),
            poi_count: 0,
            type: place.type_enum,
        })

        var poiLayer = new VectorLayer({
            source: new VectorSource({
                features: [item],
            }),
            visible: true,
            zindex: 1,
            style: poisStyle,
            name: 'PlaceLayer',
            title: 'PlaceLayer',
        })

        initialMap.addLayer(poiLayer)

        // set map onclick handler
        initialMap.on('click', handleMapClick)

        // save map and vector layer references to state
        //setMap(initialMap)
        // setFeaturesLayer(initalFeaturesLayer)
    }, [])

    const handleMapClick = event => {
        if (confirm('Confirm the new position?') == true) {
            const coords = event.coordinate
            const lngLatAr = transform(coords, 'EPSG:3857', 'EPSG:4326')
            place.latitude = lngLatAr[1]
            place.longitude = lngLatAr[0]
            console.log(place)
            item.getGeometry().setCoordinates(coords)
            setLngLat(lngLatAr)
            alert('Coordinates updated. Hit the Update Place button to save the changes.')
        }
    }

    const handleChange = e => {
        e.preventDefault()
        setPlace(Object.assign({}, place, { [e.target.name]: e.target.value }))
    }

    const handleSubmit = e => {
        e.preventDefault()

        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

        delFields(
            [
                'id',
                'popularity',
                'user_name',
                'user_city',
                'user_country_code',
                'marker',
                'user_thumb_url',
                'charts'
            ],
            place
        )

        axios
            .patch(`/api/v1/places/${place_id}`, { place })
            .then(resp => {
                // TODO there must be a better way to reset the values to null in the state
                //setPlace({ name: '', city: '', description: '' })
                navigate(`/placeview/${place_id}`)
            })
            .catch(resp => {
                alert('error in updating place')
            })
    }

    return (
        <>
            <div>
                <h4>Place Edit Page</h4>
                <div className="row">
                    <div className="col border m-2">
                        <div className="m-3 text-center">
                            Latitude: {Math.round(lngLat[1] * 1000) / 1000}{' '}
                            <span className="ms-3" />
                            Longitude: {Math.round(lngLat[0] * 1000) / 1000}
                        </div>
                        <form onSubmit={handleSubmit} className="mt-2 mb-2">
                            <div className="form-field">
                                <label className="text-muted">Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    placeholder="e.g. Snug Cove"
                                    value={place.name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-field">
                                <label className="text-muted">Type</label>
                                <select
                                    className="form-control"
                                    type="select"
                                    name="type_enum"
                                    value={place.type_enum}
                                    onChange={handleChange}
                                >
                                    <PlaceTypeOptions />
                                </select>
                            </div>

                            <div className="form-field">
                                <label className="text-muted">Address</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="address"
                                    placeholder="e.g. 117th Water Edge St"
                                    value={place.address}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-field">
                                <label className="text-muted">City</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="city"
                                    placeholder="e.g. Road Town"
                                    value={place.city}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-field">
                                <label className="text-muted">
                                    ZIP/Postal Code
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="postal_code"
                                    placeholder="e.g. 94012"
                                    value={place.postal_code}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-field">
                                <label className="text-muted">
                                    State/Province
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="state_code"
                                    placeholder="e.g. CA"
                                    value={place.state_code}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-field">
                                <label className="text-muted">Country</label>
                                <select
                                    className="form-control"
                                    type="select"
                                    name="country_code"
                                    value={place.country_code}
                                    onChange={handleChange}
                                >
                                    <CountryOptions />
                                </select>
                            </div>

                            <div className="form-field">
                                <label className="text-muted">
                                    Description
                                </label>
                                <textarea
                                    className="form-control"
                                    type="text"
                                    name="description"
                                    placeholder="e.g. Well managed marina, friendly staff, numerous guest births. Watch the sandbar at the entrance"
                                    defaultValue={place.description}
                                    onChange={handleChange}
                                />
                            </div>

                            <button type="submit" className="btn btn-primary">
                                Update place
                            </button>
                        </form>
                    </div>
                    <div className="col border m-2">
                        <div className="row">
                            <div className="col">
                                <div
                                    ref={mapElement}
                                    className="edit-map"
                                ></div>
                            </div>
                            <div className="col border rounded m-2">
                                To <strong>adjust the coordinates</strong> of
                                this Point of Interest double-click on a new
                                position in the map, then click on Update Place.
                                Adjustments can only be made within the
                                boundaries of the map.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CCLicense />
            <Link to="/">Back to map</Link>
        </>
    )
}

export default PlaceEdit
