import { GoogleCharts } from 'google-charts'
const Ch_w = 345
const Ch_h = 345

export function drawPieChart(dataAry, title, element_id) {
    if (dataAry) {
        const data = new GoogleCharts.api.visualization.DataTable()
        data.addColumn('string', '')
        data.addColumn('number', '')
        data.addRows(dataAry)

        // Set chart options
        var options = {
            title: title,
            titleTextStyle: { fontSize: 12, color: '#333333' },
            width: Ch_w,
            height: Ch_h,
            chartArea: { width: '80%', height: '70%' },
            legend: { position: 'bottom' },
        }

        // Instantiate and draw our chart, passing in some options.
        const chart = new GoogleCharts.api.visualization.PieChart(
            document.getElementById(element_id)
        )
        chart.draw(data, options)
    }
}

export function drawColumnChart(
    dataAry,
    chart_type,
    title,
    x_label,
    y_label,
    element_id,
    width = Ch_w,
    height = Ch_h
) {
    // Create the data table.
    if (dataAry) {
        const data = new GoogleCharts.api.visualization.arrayToDataTable(
            dataAry
        )
        
        // Set chart options
        var options = {
            title: title,
            titleTextStyle: { fontSize: 12, color: '#333333' },
            width: width,
            height: height,
            chartArea: { width: '85%', height: '70%' },
            legend: { position: 'top' },
            hAxis: {
                title: x_label,
                textStyle: { fontSize: 8 },
            },
            vAxis: {
                title: y_label,
                minValue: 0,
            },
        }

        var chart = null

        if (chart_type == 1) {
             chart = new GoogleCharts.api.visualization.ColumnChart(
                document.getElementById(element_id)
            )
        } else if (chart_type == 2) {
             chart = new GoogleCharts.api.visualization.AreaChart(
                document.getElementById(element_id)
            )
        } else if (chart_type == 3) {
             chart = new GoogleCharts.api.visualization.LineChart(
                document.getElementById(element_id)
            )
        } else {
             chart = new GoogleCharts.api.visualization.BubbleChart(
                document.getElementById(element_id)
            )
        }
        chart.draw(data, options)
    }
}

export function drawBubbleChart(dataAry, title, element_id) {
    // Create the data table.
    if (dataAry) {
        const data = new GoogleCharts.api.visualization.arrayToDataTable(
            dataAry
        )

        // Set chart options
        const options = {
            title: title,
            titleTextStyle: { fontSize: 12, color: '#333333' },
            width: Ch_w,
            height: Ch_h,
            chartArea: { width: '85%', height: '70%' },
            colorAxis: {
                colors: [
                    'white',
                    'blue',
                    'green',
                    'yellow',
                    'orange',
                    'red',
                    'purple',
                ],
                values: [0, 30],
                legend: { position: 'bottom' },
            },
            hAxis: {
                title: 'Sustained Wind Scale (Kts.)',
                textColor: '#fff',
                gridlines: { color: '#fff' },
                baseline: 0,
                minValue: -1.1,
                maxValue: 1.1,
            },
            vAxis: {
                textColor: '#fff',
                gridlines: { color: '#fff' },
                baseline: 0,
                minValue: -1.1,
                maxValue: 1.1,
            },
            legend: { position: 'none' },
        }

        // Instantiate and draw our chart, passing in some options.
        const chart = new GoogleCharts.api.visualization.BubbleChart(
            document.getElementById(element_id)
        )
        chart.draw(data, options)
    }
}
