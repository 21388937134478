import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { vesselThumbnail } from '../../src/helpers'
import { ImgTag } from '../../src/icons_lib'

const FollowedVessel = () => {
    const [vessels, setVessels] = useState([])
    const [addVessel, setAddVessel] = useState(false)
    const ulOwnRef = useRef()

    const handleVesselRemove = (e, item) => {
        e.persist()

        const vessel = vessels[item]
        vessel.user_id = null

        if (confirm('Remove vessel from your followed list?') == true) {
            const csrfToken = document.querySelector('[name=csrf-token').content
            axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
            axios
                .delete(`/api/v1/connections/${vessel.id}`, { vessel })
                .then(resp => {})
                .catch(resp => {})

            // update the vessels state array by deleting one element
            setVessels(prevVessels => {
                return prevVessels.filter((_, i) => i !== item)
            })
        }
    }
    function showFollowedVessels() {
        const retList = vessels.map((vessel, index) => {
            return (
                <li className="list-group-item p-1">
                    <div key={index} className="row">
                        <div className="col-2 text-center">
                            <img
                                className="img-thumbnail"
                                src={vesselThumbnail(vessel)}
                                alt={vessel.vessel_type}
                                width={50}
                            />
                        </div>
                        <div className="col-4">
                            <a href={`/vesselview/${vessel.id}`}>
                                {vessel.name}
                            </a>
                        </div>
                        <div className="col-2">
                            <a href={`/userview/${vessel.user_id}`}>
                                {vessel.user_name}
                            </a>
                        </div>
                        <div className="col-3">{vessel.mmsi}</div>
                        <div className="col-1">
                            <button
                                className="btn btn-sm"
                                onClick={e => handleVesselRemove(e, index)}
                            >
                                <ImgTag icon_name="trash3" size={20} />
                            </button>
                        </div>
                    </div>
                </li>
            )
        })
        return retList
    }
    const refreshPage = vessel => {
        if (addVessel && vessel) {
            //vessels.push(vessel)
            setVessels([...vessels, vessel])
        }
        setMmsiVessel({})
        setShowForm(false)
        setAddVessel(false)
        setEditVessel(false)
        setAction('post')
        ulOwnRef.current.style.display = 'flex'
    }

    useEffect(() => {
        // retrieve user's vessels
        axios
            .get('/api/v1/vessels/?followed=true')
            .then(response => {
                setVessels(response.data)
            })
            .catch(error => {
                console.log('error in retrieving followed vessels')
            })
    }, [])

    return (
        <div className="row">
            <div className="col border shadow rounded p-3 m-3">
                <ul id="uservessels" className="list-group">
                    <li className="list-group-item p-1">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-4">Vessel name</div>
                            <div className="col-2">User</div>
                            <div className="col-3">MMSI</div>
                            <div className="col-1"></div>
                        </div>
                    </li>
                    {showFollowedVessels()}
                </ul>
            </div>
        </div>
    )
}

export default FollowedVessel
