import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../store/user-context'
import { useContext } from 'react'
import countryList from 'react-select-country-list'
import { addComma, placeThumbnail } from '../../src/helpers'

const PlaceTile = props => {
    const place_id = props.place_id

    const userCtx = useContext(UserContext)

    const [place, setPlace] = useState({})
    const [closeMenu, setCloseMenu] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const navigate = useNavigate()

    const goToPlaceView = () => {
        navigate('/placeview', { state: place })
    }

    const goToPlaceEdit = () => {
        navigate('/placeedit', { state: { place: place, id: place_id } })
    }

    const deletePlace = () => {
        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

        if (confirm('Confirm place delete?') == true) {
            const url_str = `/api/v1/places/${place_id}`
            axios
                .delete(url_str)
                .then(resp => {
                    alert('place deleted')
                    props.callBack()
                    setCloseMenu(true)
                })
                .catch(resp => console.log('Error in deleting place', resp))
        } else {
            console.log('place delete canceled ')
        }
    }

    useEffect(() => {
        const url_str = `/api/v1/places/${place_id}`
        axios
            .get(url_str)
            .then(resp => {
                setPlace(resp.data)
            })
            .catch(resp => console.log('Error in retrieving place', resp))
    }, [place_id])

    // triggered after the API response is received
    useEffect(() => {
        setShowDelete(userCtx.loggedIn && place.user_id === userCtx.user.id),
            [place]
    })

    if (closeMenu) {
        return null
    } else {
        return (
            <div className="p-2 overlay-small">
                <img
                    className="img-thumbnail"
                    src={placeThumbnail(place)}
                    alt={place.id}
                />
                <div className="fw-bold">
                    <b>{place.name}</b>
                </div>
                <div className="place-address">{place.address}</div>
                <div className="place-city">
                    {addComma(place.city)} {place.state_code}
                </div>
                <div className="place-country">
                    {place.country_code &&
                        countryList().getLabel(place.country_code)}
                </div>
                <div className="btn-group">
                    <button
                        onClick={goToPlaceView}
                        className="btn btn-primary btn-sm"
                    >
                        View
                    </button>
                    <button
                        // disabled={!userCtx.loggedIn || place.user_id != userCtx.user.id}
                        disabled={!userCtx.loggedIn }
                        onClick={goToPlaceEdit}
                        className="btn btn-secondary btn-sm"
                    >
                        Edit
                    </button>
                    <button
                        disabled={!showDelete}
                        onClick={deletePlace}
                        className="btn btn-secondary btn-sm"
                    >
                        Delete
                    </button>
                </div>
            </div>
        )
    }
}

export default PlaceTile
