import React, { useState } from 'react'
import { get_layer, count_layers, dnld_file } from '../../src/ol_layers'
import { useContext } from 'react'
import UserContext from '../../store/user-context'
import { ImgTag } from '../../src/icons_lib'

const min_tile_zoom = 12
const min_pois_zoom = 0
const ml_zoom = 14,
    min_cont_zoom = 5 // TODO these need to be made consistent with constants in environment.rb

const ContextMenu = props => {
    const userCtx = useContext(UserContext)

    const [closeMenu, setCloseMenu] = useState(false)
    const [map, setMap] = useState(props.mapref)

    const evt = props.event
    const pix = map.getEventPixel(evt)
    const clickedCoords = map.getCoordinateFromPixel(pix)

    var place_layer = get_layer(map, 'PlaceLayer')

    const handleAddPoi = () => {
        // trigger a render in the parent component showing the Poi form
        props.callBackPoi(clickedCoords)
        setCloseMenu(true)
    }

    const handleDnldGmapTileKap = () => {
        const [ll, ur] = props.mapCorners
        const zoom = props.zoom
        const url = `api/v1/images/?x1=${ll[0]}&y2=${ur[1]}&x2=${ur[0]}&y1=${ll[1]}&file_type=gmap&file_format=kap&tile_zoom=${props.tileZoom}&zoom=${zoom}`
        dnld_file(url, 'gmap_kaps.zip')
        setCloseMenu(true)
    }

    const callLogin = () => {
        window.location.href = '/users/sign_in'
    }

    // create elements with css and handle funcs based on login and selector status
    // add a POI menu entry
    const klass1 =
        userCtx.loggedIn && userCtx.activeLayers.pois
            ? 'menuItem'
            : 'menuItemOff'

    const contextClick1 =
        userCtx.loggedIn && userCtx.activeLayers.pois ? handleAddPoi : null

    const addPoi = (
        <div className={klass1} onClick={contextClick1}>
            <span style={{ position: 'relative', top: '-5px' }}>
                <ImgTag icon_name="arrow_up_left" />
            </span>{' '}
            Add a Poi at this location
        </div>
    )

    // tile download menu entries
    const klass2 =
        userCtx.loggedIn && userCtx.activeLayers.satMaps
            ? 'menuItem'
            : 'menuItemOff'

    const contextClick2 =
        userCtx.loggedIn && userCtx.activeLayers.satMaps
            ? handleDnldGmapTileKap
            : null

    const dnldSatMap = (
        <div className={`${klass2} pb-0`} onClick={contextClick2}>
            Download Sat Maps in this view {`(zoom=${props.tileZoom})`}
        </div>
    )

    const newZoom = props.tileZoom >= 18 ? 13 : (props.tileZoom+1)

    const changeTileZoom = (
        <div className={`${klass2} pt-0`} onClick={() => props.callBackZoom(newZoom)}>
            Toggle tile zoom
        </div>
    )

    if (closeMenu) {
        return <div />
    } else {
        return (
            <div
                className="border rounded contextMenu"
                style={{ top: evt.y, left: evt.x }}
            >
                {addPoi}
                {dnldSatMap}
                {changeTileZoom}
                {!userCtx.loggedIn && (
                    <div className="menuItem" onClick={callLogin}>
                        Log in to enable menu options
                    </div>
                )}
            </div>
        )
    }
}

export default ContextMenu
