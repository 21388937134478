import React, { useState, useEffect, useContext } from 'react'
import UserContext from '../store/user-context'
import { LinkTt } from '../src/helpers'

const Selector = () => {
    const userCtx = useContext(UserContext)
    const [layerSelected, setLayerSelected] = useState(userCtx.activeLayers)

    const handleChange = e => {
        setLayerSelected(prev => {
            // toggle checkbox values
            return Object.assign({}, prev, {
                [e.target.name]: !prev[e.target.name],
            })
        })
    }

    useEffect(() => {
        // update context
        userCtx.updActiveLayers(layerSelected)
    }, [layerSelected])

    return (
        <div className="layers-overlay accordion" id="layers">
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                    >
                        Layers
                    </button>
                </h2>

                <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#layers"
                >
                    <div className="accordion-body">
                        <Checkbox
                            label=" Points of Interest"
                            name="pois"
                            value={layerSelected.pois}
                            onChange={handleChange}
                        />
                        <LinkTt
                            title="Marinas, anchorages, ports, boat ramps etc."
                            id="tt-1"
                            link="/faq#destinations"
                        />
                        <div
                            id="maps"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#layers"
                        >
                            <Checkbox
                                label=" Satellite maps"
                                name="satMaps"
                                value={layerSelected.satMaps}
                                onChange={handleChange}
                            />
                            <LinkTt
                                title="View and export satellite images of POIs into your favorite navigation app"
                                id="tt-2"
                                link="/faq#satellite_maps"
                            />
                        </div>
                        <div>
                            <Checkbox
                                label=" Weather Beacons"
                                name="buoys"
                                value={layerSelected.buoys}
                                onChange={handleChange}
                            />
                            <LinkTt
                                title="Check wind, wave, temperature etc. from weather buoys, ships and sQuiddio users"
                                id="tt-3"
                                link="/faq#weather_beacons"
                            />
                        </div>
                        <div>
                            <Checkbox
                                label=" AIS AToNs"
                                name="navAids"
                                value={layerSelected.navAids}
                                onChange={handleChange}
                            />
                            <LinkTt 
                                title="AIS Aids to Navigation" 
                                id="tt-3" 
                                link="/faq#ais_atons"
                            />
                        </div>

                        <div>
                            <Checkbox
                                label=" Vessels"
                                name="vessels"
                                disabled={!userCtx.loggedIn}
                                //disabled=
                                value={layerSelected.vessels}
                                onChange={handleChange}
                            />
                            <LinkTt
                                title="Follow a fleet of vessels around the world (requires free subscription)"
                                id="tt-3"
                                link="/faq#vessel_tracking"
                            />
                        </div>

                        {/* <div>
                            <Checkbox
                                label=" Traffic Areas"
                                name="aisPaths"
                                //disabled={!userCtx.loggedIn}
                                value={layerSelected.aisPaths}
                                onChange={handleChange}
                            />
                            <LinkTt
                                title="Keep a good lookout and your radar on in these areas!"
                                id="tt-3"
                                link="/faq#vessel_tracking"
                            />
                        </div> */}
                        <div>
                            <Checkbox
                                label=" Superyacht Dodger"
                                name="stinkPots"
                                value={layerSelected.stinkPots}
                                onChange={handleChange}
                            />
                            <LinkTt
                                title="Enjoy a peaceful night at anchor, away from superyachts and cruise ships"
                                id="tt-3"
                                link="/faq#stink_pots"
                            />
                        </div>

                        <button
                            className="btn btn-sm btn-success mt-2 p-0"
                            style={{width: "100%"}}
                            data-bs-toggle="modal"
                            data-bs-target="#intro-modal"
                        >
                            more info
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Checkbox = ({ label, name, value, onChange, disabled = false }) => {
    return (
        <label>
            <input
                type="checkbox"
                name={name}
                checked={value}
                onChange={onChange}
                disabled={disabled}
            />
            {label}
        </label>
    )
}

export default Selector
