import React, { useState, useContext, useRef } from 'react'
import axios from 'axios'
import { strToDate, dateToStr, LinkTt } from '../../src/helpers'
import UserContext from '../../store/user-context'

const PostForm = props => {
    // const [post, setPost] = useState({comment: ''})
    //const [done, setDone] = useState(false)
    const userCtx = useContext(UserContext)
    const [file, setFile] = useState(null)
    const [fields, setFields] = useState({ comment: '' })
    //const fieldsRef = useRef

    const user_name = userCtx.loggedIn && (userCtx.user.first_name + ' ' + userCtx.user.last_name)

    const handleChange = e => {
        e.preventDefault()
        setFields(
            Object.assign({}, fields, { [e.target.name]: e.target.value })
        )
    }

    const handleSubmit = e => {
        e.preventDefault()
        // TODO: need to standardize on poi_id or place_id?

        // formData includes the multipart parameters to upload a file
        const post = new FormData()
        post.append('post[comment]', fields.comment)
        post.append('post[poi_id]', props.place.id)
        if (file) {
            post.append('post[photo][image]', file)
        }

        // TODO this should add the user_name to the fields, which is then passed to the callBack to update the list
        setFields({ ...fields, user_name: user_name })

        console.log('fields: ', fields)
        // need the following to inspect a formData object. Console.log won't do
        //for(var pair of post.entries()) {console.log(pair[0]+', '+pair[1]);}

        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
        $('#ajax-loader').show()
        
        axios
            .post('/api/v1/posts', post)

            .then(resp => {
                // add the new post to the top of the list in the user feed (currently missing created_at and uploaded image)
                //fields['created_at'] = dateToStr(new Date(Date.now())) // TODO can't get this to work UserFeed expects a string date in format

                props.callBack(fields)

                setFields({ comment: '' })
                //TODO can't get this to clear file in the form using the Value property on the field, as I do for comment
                setFile(null)
                $('#ajax-loader').hide()
                alert('Thank you: your post was added')
            })
            .catch(resp => {
                console.log('fallito! ', resp)
            })
    }

    return (
        <>
            {/* <h6 className="m-2">Have any info on {props.place.name}?</h6> */}
            {userCtx.loggedIn ? (
                <form onSubmit={handleSubmit}>
                    <div className="field m-2">
                        <label htmlFor="" className="form-label me-2">
                            Post a review or comment:{' '}
                        </label>
                        <input
                            onChange={handleChange}
                            type="text"
                            name="comment"
                            value={fields.comment}
                            id="validationCustom01"
                            placeholder="e.g. Definitely recommend a visit and overnight stay, but check the depth at the entrance!"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="field m-2">
                        <label htmlFor="" className="form-label me-2">
                            Upload an image (optional)
                            <LinkTt
                                title="Uploaded images are added to the image carousel above"
                                id="id-2"
                                link="/faq#upload_photo"
                            />
                            :
                        </label>
                        <input
                            className="form-control"
                            type="file"
                            onChange={e => {
                                setFile(e.target.files[0])
                            }}
                        />
                    </div>
                    <button className="btn btn-primary m-2" type="submit">
                        Submit
                    </button>
                </form>
            ) : (
                <p className="text-center">
                    You must be <a href="/users/sign_in">logged in</a> to submit
                    a post or an image
                </p>
            )}
        </>
    )
}

export default PostForm
