import React, { useState, useContext, useEffect, Fragment } from 'react'
import axios from 'axios'
import PopPlace from './PopPlace'
import UserContext from '../../../store/user-context'

const PopPlaces = () => {
    const userCtx = useContext(UserContext)

    // if (userCtx.features) {
    //     console.log('poplaces layer: ', userCtx.features)
    // }

    const isValidPoi = x => {
        // TODO - on the first rendering, the layer source still contains items from the default
        // view request which returns cluster pseudo-pois with ids like 1,2,5 etc. I suspect the initial default view request
        // is too close to the user view request and the responses get merged?
        return x.values_.id > 1400
    }
    
    const selPois = userCtx.features
    .filter(isValidPoi)
    .map(feature => feature.values_)
    
    // sort by popularity and take the first 10
    const sortedPois = selPois.sort((p1, p2) =>
    p1.popularity < p2.popularity
    ? 1
    : p1.popularity > p2.popularity
    ? -1
    : 0
    ).slice(0, 10)

    const poiItems = sortedPois.map(item => {
        if (item.id > 1400) {
            item.type_enum = item.type
            return <PopPlace key={item.id} place={item} />
        }
    })

    return (
        <>
            <h5>Top destinations in the area</h5>
            <ul className="list-group">
                <li className="list-group-item p-1">
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col">Name</div>
                        <div className="col">Traffic index</div>
                    </div>
                </li>
                {poiItems}
            </ul>
        </>
    )
}

export default PopPlaces
