import React, { useState, useEffect, Fragment } from 'react'

const MeteoRepTile = props => {
    const meteo_rep = props.meteo_rep
    const obs = meteo_rep.observations

    function removeValues(values, arr) {
        var found
        values.forEach(val => {
            found = arr.indexOf(val)
            if (found >= 0) {
                arr.splice(found, 1)
            }
        })
        return arr
    }

    function convValues(meth, val) {
        if (!val) return null
        if (['wspd', 'gst'].indexOf(meth) >= 0) {
            return Math.round((val * 36000) / 1850) / 10
        } else if (['pres', 'atmp', 'atmp', 'wtmp', 'apd'].indexOf(meth) >= 0) {
            return Math.round(val)
        } else {
            return val
        }
    }
    
    // remove some of the columns to save space
    const header = removeValues(
        ['obs_time', 'longitude', 'latitude'],
        meteo_rep.header
    )

    const [closeMenu, setCloseMenu] = useState(false)
    const unitSymbols = {
        wdir: '°',
        wspd: 'kt',
        gst: 'kt',
        wvht: 'mt',
        dpd: 'sec',
        apd: 'sec',
        mwd: '°',
        pres: 'hPa',
        atmp: '°C',
        wtmp: '°C',
        dewp: '°C',
        vis: 'nm',
        ptdy: 'hPa',
        tide: 'ft',
        distance: 'nm'
    }

    if (closeMenu) {
        return <p>MeteoRep deleted</p>
    } else {
        return (
            <div className="overlay-large">
                <h5 className="">Weather near: {meteo_rep.name}</h5>
                <p>
                    Lng: {meteo_rep.centr_longitude}, Lat:{' '}
                    {meteo_rep.centr_latitude}
                </p>
                <div className="container d-flex justify-content-center mb-2">
                    <table>
                        <thead>
                            <tr className="bg-secondary text-white">
                                {header.map((title, index) => {
                                    return (
                                        <th
                                            key={index}
                                            className="ps-2"
                                            scope="col"
                                        >
                                            <div>{title}</div>
                                            <div className="tiny-font">
                                                {unitSymbols[title]}
                                            </div>
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody className="smaller-font">
                            {obs.map((ob, index) => {
                                return (
                                    <tr key={index}>
                                        {header.map((meth, index) => {
                                            return (
                                                <td
                                                    key={index}
                                                    className="ps-2"
                                                >
                                                    {convValues(meth, ob[meth])}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default MeteoRepTile
