import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import VesselForm from './VesselForm'
import { vesselThumbnail } from '../../src/helpers'
import { ImgTag } from '../../src/icons_lib'
import { delFields } from '../../src/helpers'

const UserVessel = () => {
    const [vessels, setVessels] = useState([])
    const [mmsi, setMmsi] = useState('')
    const [mmsiVessels, setMmsiVessels] = useState([])
    const [mmsiVessel, setMmsiVessel] = useState({})
    const [addVessel, setAddVessel] = useState(false)
    const [editVessel, setEditVessel] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [action, setAction] = useState('post')
    const ulOwnRef = useRef()
    const ulMmsiRef = useRef()
    const inputRef = useRef()

    //const navigate = useNavigate()

    function openAddVessel() {
        setAddVessel(true)
    }
    function openForm() {
        setShowForm(true)
    }
    function getVesselCandidates(partial_mmsi) {
        // retrieve list of vessel candidates based on partial MMSI
        if (partial_mmsi.length > 3) {
            axios
                .get(`/api/v1/vessels/?partial_mmsi=${partial_mmsi}`)
                .then(response => {
                    setMmsiVessels(response.data)
                })
                .catch(error => {
                    console.log('error in retrieving user boats')
                })
        }
    }
    const handleVesselRemove = (e, item) => {
        // we don't actually delete the record. Only change the ownership

        e.persist()

        const vessel = vessels[item]
        vessel.user_id = null

        delFields(['user_first_name', 'user_last_name', 'user_name'], vessel)

        if (confirm('Remove vessel from your list?') == true) {
            const csrfToken = document.querySelector('[name=csrf-token').content
            axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
            axios
                .patch(`/api/v1/vessels/${vessel.id}`, { vessel })
                .then(resp => {})
                .catch(resp => {})

            // update the vessels state array by deleting one element
            setVessels(prevVessels => {
                return prevVessels.filter((_, i) => i !== item)
            })
        }
    }
    function showOwnVessels() {
        // display picklist of owner's vessels
        const retList = vessels.map((vessel, index) => {
            return (
                <li className="list-group-item">
                    <div key={index} className="row">
                        <div className="col-2 text-center">
                            <img
                                className="img-thumbnail"
                                src={vesselThumbnail(vessel)}
                                alt={vessel.vessel_type}
                                width={50}
                            />
                        </div>
                        <div className="col-5">
                            <a href={`/vesselview/${vessel.id}`}>
                                {vessel.name}
                            </a>
                        </div>
                        <div className="col-3">{vessel.mmsi}</div>
                        <div className="col-2">
                            <button
                                className="btn btn-sm"
                                onClick={e => handleVesselRemove(e, index)}
                            >
                                <ImgTag icon_name="trash3" size={20} />
                            </button>
                        </div>
                    </div>
                </li>
            )
        })
        return retList
    }
    function showVesselCandidates(list) {
        // display picklist of vessel candidates
        if (list.length == 0) {
            return (
                <div className="border p-2">
                    MMSI not available in the database or already in use by
                    another vessel. Please check the number, or create a vessel
                    without an MMSI. If you own this MMSI, you will be able to
                    add it to the vessel's record in your user profile.
                </div>
            )
        }
        return list.map((listItem, index) => {
            return (
                <button
                    type="button"
                    key={index}
                    onClick={e => {
                        inputRef.current.value = listItem.mmsi
                        ulMmsiRef.current.style.display = 'none'
                        const vessels = mmsiVessels.filter(
                            v => v.mmsi == listItem.mmsi
                        )
                        setMmsiVessel(vessels[0])
                        setShowForm(true)
                        setAction('patch')
                    }}
                    className="list-group-item list-group-item-action"
                >
                    <div className="row">
                        <div className="col">{listItem.name}</div>
                        <div className="col">{listItem.mmsi}</div>
                    </div>
                </button>
            )
        })
    }
    const refreshPage = vessel => {
        if (addVessel && vessel) {
            //vessels.push(vessel)
            setVessels([...vessels, vessel])
        }
        setMmsiVessel({})
        setShowForm(false)
        setAddVessel(false)
        setEditVessel(false)
        setAction('post')
        ulOwnRef.current.style.display = 'flex'
    }

    useEffect(() => {
        // retrieve user's vessels
        axios
            .get('/api/v1/vessels/?own=true')
            .then(response => {
                setVessels(response.data)
            })
            .catch(error => {
                console.log('error in retrieving user boats')
            })
    }, [])

    useEffect(() => {
        // set bounce timer for search field
        const timeOutId = setTimeout(() => getVesselCandidates(mmsi), 500)
        return () => clearTimeout(timeOutId)
    }, [mmsi])

    return (
        <div className="row">
            <div className="col-md border shadow rounded p-3 m-3">
                <ul id="uservessels" className="list-group" ref={ulOwnRef}>
                    <li className="list-group-item p-1">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-5">Vessel Name</div>
                            <div className="col-3">MMSI</div>
                            <div className="col-2"></div>
                        </div>
                    </li>
                    {showOwnVessels()}
                </ul>
                {vessels.length <= 5 ? (
                    <div className="row justify-content-center mt-3">
                        <div className="col-md-4 text-center">
                            <button
                                onClick={openAddVessel}
                                className="btn btn-secondary"
                            >
                                Add a new vessel
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>You have reached the maximum allwed number of vessels</p>
                )}
            </div>

            {addVessel || editVessel ? (
                <div className="col-md border shadow rounded p-3 m-3">
                    <div>
                        {addVessel ? <h5>Add Vessel</h5> : <h5>Edit Vessel</h5>}
                        {showForm ? (
                            // using the same form for adding and editing vessel
                            <VesselForm
                                vessel={mmsiVessel}
                                action={action}
                                addVessel={addVessel}
                                callBack={refreshPage}
                            />
                        ) : (
                            // This is only executed when AddVessel is true. editVessel sets showForm to true only
                            <div>
                                <div className="field">
                                    <input
                                        id="search-bar"
                                        className="form-control"
                                        type="text"
                                        name="mmsi"
                                        placeholder="Vessel's MMSI (optional)"
                                        ref={inputRef}
                                        onChange={event =>
                                            // setting the partial mmsi also triggers the effect that retrieves vessel candidates
                                            // and fires the pulldown below
                                            setMmsi(event.target.value)
                                        }
                                    />
                                </div>
                                <div>
                                    {mmsi && (
                                        // vessel candidates pull down
                                        <ul
                                            id="results"
                                            className="list-group"
                                            ref={ulMmsiRef}
                                        >
                                            {showVesselCandidates(mmsiVessels)}
                                        </ul>
                                    )}
                                </div>
                                <div className="row justify-content-center mt-3">
                                    <div className="col-4">
                                        <button
                                            className="btn btn-secondary mt-2"
                                            onClick={openForm}
                                        >
                                            I don't have a MMSI
                                        </button>
                                    </div>
                                    <div className="col-3">
                                        <button
                                            className="btn btn-light mt-2"
                                            onClick={() => refreshPage(null)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    )
}

export default UserVessel
