import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { strToDate, vesselThumbnail } from '../../../src/helpers'
import { Link } from 'react-router-dom'
import { LinkTt } from '../../../src/helpers'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

const CheckedIn = props => {
    const [checkins, setCheckins] = useState([])

    useEffect(() => {
        const checkins_url_str = `/api/v1/checkins?place_id=${props.place.id}`
        if (props.place.id) {
            axios
                .get(checkins_url_str)
                .then(resp => {
                    setCheckins(resp.data)
                    props.callBack('vessels', resp.data && resp.data.length)
                })
                .catch(resp =>
                    console.log('Error in retrieving checkins for place', resp)
                )
        }
    }, [props.place])

    return (
        <>
            <h6 className="bg-secondary text-white p-1">
                These vessels are checked in here
                <LinkTt
                    title="Vessels that have entered the POI's area of detection (last 7 days only)"
                    id="id-1"
                    link="/faq#checking_in"
                />{' '}
            </h6>
            <ul className="list-group">
                <li className="list-group-item p-1">
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col">Name</div>
                        <div className="col-1">LOA (mt)</div>
                        <div className="col-2">MMSI</div>
                        <div className="col-1">Flag</div>
                        <div className="col">Checked in:</div>
                    </div>
                </li>
                {checkins.map((checkin, index) => {
                    return (
                        <li className="list-group-item" key={index}>
                            <div className="row">
                                <div className="col-1">
                                    <img
                                        className="img-thumbnail"
                                        src={vesselThumbnail(checkin)}
                                        alt={checkin.vessel_type}
                                        width={50}
                                    />
                                </div>
                                <div className="col">
                                    <Link
                                        to={`/vesselview/${checkin.vessel_id}`}
                                    >
                                        {checkin.vessel_name}
                                    </Link>
                                </div>
                                <div className="col-1">
                                    {checkin.vessel_loa}
                                </div>
                                <div className="col-2">
                                    {checkin.vessel_mmsi}
                                </div>
                                <div className="col-1">
                                    {checkin.vessel_country_registration &&
                                        getUnicodeFlagIcon(
                                            checkin.vessel_country_registration
                                        )}
                                </div>
                                <div className="col">
                                    {checkin.updated_at + ' ago'}
                                    {/* {strToDate(checkin.updated_at)} */}
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

export default CheckedIn
