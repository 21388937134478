import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import UserContext from '../../store/user-context'
import { useContext } from 'react'
import { addComma } from '../../src/helpers'

const NavAidTile = props => {
    const navAid_id = props.nav_aid_id

    const userCtx = useContext(UserContext)

    const [navAid, setNavAid] = useState({})
    const [closeMenu, setCloseMenu] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const navigate = useNavigate()

    const deleteNavAid = () => {
        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

        if (confirm('Confirm nav_aid delete?') == true) {
            const url_str = `/api/v1/nav_aids/${navAid_id}`
            axios
                .delete(url_str)
                .then(resp => {
                    props.callBack()
                    setCloseMenu(true)
                })
                .catch(resp => console.log('Error in deleting nav_aid', resp))
        } else {
            console.log('nav_aid delete canceled ')
        }
    }

    useEffect(() => {
        const url_str = `/api/v1/nav_aids/${navAid_id}`
        axios
            .get(url_str)
            .then(resp => {
                setNavAid(resp.data)
            })
            .catch(resp => console.log('Error in retrieving nav_aid', resp))
    }, [navAid_id])

    // triggered after the API response is received
    useEffect(() => {
        setShowDelete(userCtx.loggedIn && navAid.user_id === userCtx.user.id),
            [navAid]
    })

    if (closeMenu) {
        return <p>NavAid deleted</p>
    } else {
        return (
            <div className="p-1 overlay-medium">
                {navAid.thumb_url && (
                    <img
                        className="img-thumbnail"
                        src={navAid.thumb_url}
                        alt={navAid.name}
                    />
                )}
                <div className="place-name">
                    <b>{navAid.name}</b>
                </div>

                <ul className="smaller-font">
                    <li className="list-group" key={0}>
                        <div className="row text-start">
                            <div className="col-3">Type:</div>
                            <div className="col">
                                {navAid.type_text && navAid.type_text.name}
                            </div>
                        </div>
                    </li>

                    <li className="list-group" key={1}>
                        <div className="row text-start">
                            <div className="col-3">MMSI:</div>
                            <div className="col">
                                {navAid.mmsi && navAid.mmsi}
                            </div>
                        </div>
                    </li>
                    {(navAid.address || navAid.city || navAid.state_code) && (
                        <li className="list-group" key={2}>
                            <div className="row text-start">
                                <div className="col-3">Location:</div>
                                <div className="col">
                                    {addComma(navAid.address)}
                                    {addComma(navAid.city)}
                                    {navAid.state_code}
                                </div>
                            </div>
                        </li>
                    )}
                    <li className="list-group" key={3}>
                        <div className="row text-start">
                            <div className="col-3">Updated:</div>
                            <div className="col">{navAid.updated_at}</div>
                        </div>
                    </li>
                    {navAid.dims && (
                        <li className="list-group" key={4}>
                            <div className="row text-start">
                                <div className="col-3">Dimensions:</div>
                                <div className="col">
                                    {navAid.dims[0]}x{navAid.dims[1]} mt.
                                </div>
                            </div>
                        </li>
                    )}
                </ul>

                {showDelete && (
                    <div className="btn-group">
                        <button
                            className="btn btn-secondary btn-sm mt-2"
                            // disabled={!showDelete}
                            onClick={deleteNavAid}
                        >
                            Delete NavAid
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

export default NavAidTile
