import React from 'react'
import { Link } from 'react-router-dom'

const ConnectionTile = props => {
    const connection = props.connection
    
    return (
        <div className="p-1 overlay-medium">
            {connection.vessel_thumb_url && (
                <img
                    className="img-thumbnail"
                    src={connection.vessel_thumb_url}
                    alt={connection.id}
                />
            )}
            <div className="place-name">
                <b>{connection.vessel_name}</b>
            </div>

            <ul className="smaller-font">
                <li className="list-group" key={0}>
                    <div className="row text-start">
                        <div className="col-3">Updated:</div>
                        <div className="col">{connection.time_ago} ago</div>
                    </div>
                </li>

                <li className="list-group" key={1}>
                    <div className="row text-start">
                        <div className="col-3">SOG:</div>
                        <div className="col">{connection.sog}</div>
                    </div>
                </li>
                <li className="list-group" key={2}>
                    <div className="row text-start">
                        <div className="col-3">COG:</div>
                        <div className="col">{connection.heading}</div>
                    </div>
                </li>
                {connection.place_name && (
                    <li className="list-group" key={3}>
                        <div className="row text-start">
                            <div className="col-3">Checkedin:</div>
                            <div className="col">
                                <div> <Link to={`/placeview/${connection.place_id}`}>{connection.place_name}</Link></div>
                                <div> {connection.when_checked_in} ago </div>
                            </div>
                        </div>
                    </li>
                )}
                <li className="list-group" key={4}>
                    <div className="row text-start">
                        <div className="col-3">View:</div>
                        <div className="col">
                            <Link to={`/vesselview/${connection.vessel_id}`}>
                                {connection.vessel_name}
                            </Link>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default ConnectionTile
