import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import UserContext from '../store/user-context'

import VesselPhotos from '../components/Vessel/VesselPhotos'
import Chart from '../components/Vessel/Chart'
import VesselShow from '../components/Vessel/VesselShow'
import VesselEdit from '../components/Vessel/VesselEdit'
import VesselPosition from '../components/Vessel/VesselPosition'
import { LinkTt, vesselThumbnail, vesselIconPath } from '../src/helpers'
import { ImgTag } from '../src/icons_lib'

const VesselView = () => {
    const [vessel, setVessel] = useState({})
    const location = useLocation()
    const userCtx = useContext(UserContext)
    const [edit, setEdit] = useState(false)

    document.title = `Squidd.io ${vessel && vessel.vessel_type} vessel: ${vessel.name}`
    document.getElementsByTagName('meta')["description"].content = `Information about ${vessel.name}. Type, length over all, draft, beam, IMO number, call sign, home port, current location, photos`;
    
    // useEffect(() => {
    //     setVessel(props.vessel)
    // }, [props])

    const handleEdit = () => {
        setEdit(true)
    }

    const handleFollow = () => {
        if (confirm('Add this vessel to your followed list?') == true) {
            const csrfToken = document.querySelector('[name=csrf-token').content
            axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

            axios
                .post(`/api/v1/connections/?vessel_id=${vessel.id}`)
                .then(resp => {
                    alert(
                        `Vessel ${vessel.name} added to your follow list. View and manage your follow list in your user profile`
                    )
                    // vessel.is_followed = true
                    setVessel({ ...vessel, is_followed: true })
                })
                .catch(resp => {})
        }
    }

    const handleUnFollow = () => {
        if (
            confirm('Confirm removing this vessel from your followed list?') ==
            true
        ) {
            const csrfToken = document.querySelector('[name=csrf-token').content
            axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

            axios
                .delete(`/api/v1/connections/${vessel.id}`, { vessel })
                .then(resp => {
                    alert(
                        `Vessel ${vessel.name} removed from your follow list.`
                    )
                    //vessel.is_followed = false
                    setVessel({ ...vessel, is_followed: false })
                })
                .catch(resp => {})
        }
    }

    const refreshPage = updVessel => {
        setVessel(updVessel)
        setEdit(false)
    }

    const [file, setFile] = useState(null)

    useEffect(() => {
        if (location.state) {
            // internal react request, already includes vessel object
            setVessel(location.state)
        } else {
            // external request, need to retrieve vessel from API
            const pathname = location.pathname
            const vessel_id = pathname.split('/').slice(-1)[0]
            const url_str = `/api/v1/vessels/${vessel_id}`
            axios
                .get(url_str)
                .then(resp => {
                    setVessel(resp.data)
                })
                .catch(resp => console.log('Error in retrieving vessel', resp))
        }
    }, [])

    return (
        vessel && (
            <>
                <div className="row p-2">
                    <div className="col-md-9 ps-4">
                        <div className="to_left mt-1">
                            <Link to="/">
                                <LinkTt title="Back to Map">
                                    <ImgTag icon_name="globe_arrow" size={40} />
                                </LinkTt>
                            </Link>
                        </div>

                        <div className="col to_left ms-2">
                            <img
                                className="img-thumbnail"
                                // src={vesselIconPath(vessel.vessel_type)}
                                src={vesselThumbnail(vessel)}
                                alt={vessel.vessel_type}
                            />
                        </div>
                        <h2 className="to_left ms-2">{vessel.name}</h2>
                    </div>
                    <div className="col-md-3 mt-1 text-center">
                        {userCtx.loggedIn && userCtx.user.id == vessel.user_id && (
                            <button
                                className="btn btn-sm btn-primary"
                                onClick={handleEdit}
                                disabled={edit}
                            >
                                Edit
                            </button>
                        )}
                        {userCtx.loggedIn &&
                            (vessel.is_followed ? (
                                <button
                                    className="btn btn-sm btn-primary ms-2"
                                    onClick={handleUnFollow}
                                >
                                    Unfollow Vessel
                                </button>
                            ) : (
                                <button
                                    className="btn btn-sm btn-primary ms-2"
                                    onClick={handleFollow}
                                >
                                    Follow Vessel
                                </button>
                            ))}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md">
                        {edit ? (
                            <VesselEdit
                                vessel={vessel}
                                callBack={refreshPage}
                            />
                        ) : (
                            <VesselShow vessel={vessel} />
                        )}
                    </div>

                    <div className="col-md">
                        <div className="row border shadow rounded">
                            <VesselPosition vessel={vessel} />
                        </div>
                        <div className="row border shadow rounded  mt-3">
                            <VesselPhotos vessel={vessel} userCtx={userCtx} />
                        </div>
                    </div>
                </div>
                <div className="row shadow mt-2">
                    <div className="col">
                        <Chart charts={vessel && vessel.charts}></Chart>
                    </div>
                </div>
            </>
        )
    )
}

export default VesselView
