import React from 'react'

const CCLicense = () => {
    return (
        <div className="mt-3 text-center">
            <img
                width="60px"
                src="https://licensebuttons.net/l/by-sa/3.0/88x31.png"
                alt="commons-license-button"
            />
            <span className="ms-3 small-text">
                Content provided to sQuiddio is governed by the{' '}
                <a href="https://creativecommons.org/licenses/by-sa/4.0/">
                    Creative Commons BY-SA License rev. 3{' '}
                </a>
            </span>
        </div>
    )
}

export default CCLicense
