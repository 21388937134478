// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
//require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

// note the ./ preceeding the file name. It is key! it signifies it's in the app/javascripts/pack directory
// and not the app/javascripts dir. See the preceding require('channels')
require('./src/ol_map.js')

// require('./test.js')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//require('./App')
require('./index')
require('bootstrap')
//require('bootstrap-icons')
//require('./gstatic.com_charts_loader.js')
require('./src/draw_charts.js')
require('./src/helpers.js')
import 'bootstrap/dist/css/bootstrap'
//import 'bootstrap-icons/icons'

// note the additional resolved_paths: ['app/assets'] in config/webpacker.yml 
import 'stylesheets/application.css'
import 'stylesheets/override.css'
import 'stylesheets/static.css'
import 'stylesheets/ol_map.css'
import 'stylesheets/carousel.css'
import 'stylesheets/markers.css'
import 'stylesheets/olayers/v6-5-0-dist/ol.css'
