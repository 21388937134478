import React from 'react'
//import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App'
import Profile from './Profile'

import { UserContextProvider } from './store/user-context'

document.addEventListener('DOMContentLoaded', () => {
    const appContainer = document.getElementById('root')
    const configContainer = document.getElementById('config')

    if (appContainer) {
        // main application
        const root = createRoot(appContainer)
        root.render(
            <UserContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </UserContextProvider>
        )
    } else if (configContainer) {
        // user profile and other settings
        const root = createRoot(configContainer)
        root.render(
            <UserContextProvider>
                <Profile />
            </UserContextProvider>
        )
    }
})
