import React, { useState, useEffect, useRef, Fragment } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import countryList from 'react-select-country-list'
import { Place_atts } from '../src/helpers'
import { ImgTag } from '../src/icons_lib'

const Search = props => {
    const [query, setQuery] = useState('')
    const [places, setPlaces] = useState([])
    const [vessels, setVessels] = useState([])
    const [hitCount, setHitCount] = useState([])
    const ulRef = useRef()
    const inputRef = useRef()

    const navigate = useNavigate()

    const apiRequest = query => {
        if (query.length > 0) {
            axios
                .get(`/api/v1/search/?query=${query}`)
                .then(resp => {
                    setPlaces(resp.data.places)
                    setVessels(resp.data.vessels)
                    setHitCount([resp.data.places_hits, resp.data.vessels_hits])
                })
                .catch(resp => {})
        }
    }

    const goToPlaceView = place => {
        // navigate('/placeview/', { state: place })
        navigate(`/placeview/${place.id}`)
    }

    const goToVesselView = vessel => {
        navigate(`/vesselview/${vessel.id}`)
    }

    useEffect(() => {
        // close pull down and copy selected item to search field when clicking on item
        inputRef.current.addEventListener('click', event => {
            event.stopPropagation()
            ulRef.current.style.display = 'flex'
            setQuery(event.target.value)
        })
        // close pull down when clicking somewhere else on the page
        document.addEventListener('click', event => {
            ulRef.current && (ulRef.current.style.display = 'none')
        })
    }, [])

    useEffect(() => {
        // avoid multiple API requests when typing (bounce)
        const timeOutId = setTimeout(() => apiRequest(query), 500)
        return () => clearTimeout(timeOutId)
    }, [query])

    return (
        <div>
            <div className="input-group mb-3 ">
                <span className="input-group-text" id="basic-addon1">
                    <ImgTag icon_name="binoculars" />
                </span>
                <input
                    id="search-bar"
                    className="form-control"
                    type="text"
                    name="query_text"
                    placeholder="Place name or location, vessel name, MMSI or home port etc."
                    ref={inputRef}
                    onChange={event => setQuery(event.target.value)}
                />
            </div>
            <ul id="results" className="list-group search-expandable" ref={ulRef}>
                {places && places.length > 0 && (
                    <button className="btn btn-secondary" disabled>
                        POIs
                    </button>
                )}
                {places.map((place, index) => {
                    return (
                        <button
                            type="button"
                            key={index}
                            onClick={e => {
                                inputRef.current.value = place.name
                                console.log('link to place view....', place.id)
                                goToPlaceView(place)
                                
                            }}
                            className="list-group-item list-group-item-action"
                        >
                            <div className="row">
                                <div className="col">
                                    {Place_atts[place.type_enum].name}
                                    {/* {place.type_enum} */}
                                </div>
                                <div className="col">{place.name}</div>
                                <div className="col">{place.city}</div>
                                <div className="col">{place.state_code}</div>
                                <div className="col">
                                    {place.country_code &&
                                        countryList().getLabel(
                                            place.country_code
                                        )}
                                </div>
                            </div>
                        </button>
                    )
                })}
                {hitCount[0] > places.length && (
                    <button disabled>
                        {hitCount[0] - 10} more........Refine search
                    </button>
                )}
                {vessels && vessels.length > 0 && (
                    <button className="btn btn-secondary" disabled>
                        Vessels
                    </button>
                )}
                {vessels.map((vessel, index) => {
                    return (
                        <button
                            type="button"
                            key={index}
                            onClick={e => {
                                inputRef.current.value = vessel.name
                                goToVesselView(vessel)
                            }}
                            className="list-group-item list-group-item-action"
                        >
                            <div className="row">
                                <div className="col">{vessel.vessel_type}</div>
                                <div className="col">{vessel.name}</div>
                                <div className="col">{vessel.mmsi}</div>
                                <div className="col">{vessel.country_name}</div>
                            </div>
                        </button>
                    )
                })}
                {hitCount[1] > vessels.length && (
                    <button disabled>
                        {' '}
                        {hitCount[1] - 10} more........Refine search
                    </button>
                )}
            </ul>
        </div>
    )
}
export default Search
