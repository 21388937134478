import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { drawPieChart, drawColumnChart, drawBubbleChart } from '../../../src/draw_charts'
import { GoogleCharts } from 'google-charts'

const Chart = props => {
    if (!props.charts) {
        return <></>
    }
    const chartData = props.charts

    // tried passing the api key to see if it improved response times to no avail....
    // GoogleCharts.load(drawPieChart, {'mapsApiKey': '_Google_api_key'})
    GoogleCharts.load(drawPieChart)
    GoogleCharts.load(drawColumnChart)
    GoogleCharts.load(drawBubbleChart)

    useEffect(() => {
        // set the section counter to the number of charts
        props.callBack('charts', Object.keys(chartData).length)
    }, [props.place])

    const renderCharts = () => {
        $('#ajax-loader').show()
        // need to wait until the libraries are loaded before calling the functions below
        setTimeout(function() {
            $('#ajax-loader').hide()
            drawPieChart(
                chartData.class_ary,
                'Check-ins by Vessel LOA',
                'chart_class'
            )
            drawPieChart(
                chartData.type_ary,
                'Check-ins by Vessel Type',
                'chart_type'
            )
            drawColumnChart(
                chartData.month_checkin,
                1,
                'Check-ins by Month',
                'Month',
                '# of Checkins',
                'chart_month'
            )
            drawColumnChart(
                chartData.wspd_mnth_ary,
                3,
                '30-day Wind Speed',
                'Day',
                'Knots',
                'wspd_mnth_ary'
            ),
                drawBubbleChart(
                    chartData.wdir_mnth_ary,
                    '30-day Wind Direction and Speed',
                    'wdir_mnth_ary'
                ),
                drawColumnChart(
                    chartData.temp_mnth_ary,
                    3,
                    '30-day Temperature',
                    'Day',
                    '°C',
                    'temp_mnth_ary'
                ),
                drawColumnChart(
                    chartData.wspd_day_ary,
                    3,
                    '24-hour Wind Speed',
                    'Hour (GMT)',
                    'Knots',
                    'wspd_day_ary'
                ),
                drawColumnChart(
                    chartData.wdir_day_ary,
                    3,
                    '24-hour Wind Direction',
                    'Hour (GMT)',
                    'Dir.',
                    'wdir_day_ary'
                ),
                drawColumnChart(
                    chartData.temp_day_ary,
                    3,
                    '24-hour Temperature',
                    'Hour (GMT)',
                    '°C',
                    'temp_day_ary'
                )
        }, 7000)
        // TODO the minimum set up time for google charts is very erratic. Sometimes 1 sec is enougth, sometime you need
        // 10 secs of more!! Not sure what to do about it.
        // https://stackoverflow.com/questions/18998789/google-charts-taking-long-time-to-load
    }

    return (
        <div >
            <div className="row">
                <div id="chart_class" className="col-md-3 col-lg-3"></div>
                <div id="chart_type" className="col-md-3 col-lg-3"></div>
                <div id="chart_month" className="col-md-3 col-lg-3"></div>
            </div>
            <div className="row mt-2">
                <div id="wspd_day_ary" className="col-md-3 col-lg-3"></div>
                <div id="wspd_mnth_ary" className="col-md-3 col-lg-3"></div>
                <div id="wdir_day_ary" className="col-md-3 col-lg-3"></div>
                <div id="wdir_mnth_ary" className="col-md-3 col-lg-3"></div>
            </div>
            <div className="row mt-2">
                <div id="temp_day_ary" className="col-md-3 col-lg-3"></div>
                <div id="temp_mnth_ary" className="col-md-3 col-lg-3"></div>
            </div>
            {renderCharts()}
        </div>
    )
}

export default Chart
