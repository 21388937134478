import React from 'react'

// =================================================== Asset paths for icons in asset pipeline
//
// Recap on how rails manages assets:
//
// in development:
// http://localhost:3000/assets/logo.png
// rails looks for logo.png first in /public/assets/ then, failing that, in /app/assets/images/logo.png 
// (looks in images or stylesheets based on the extension )
// 
// in production:
//
// http://138.197.237.80:3000/assets/logo.png 
//
// it looks in /public/assets only and doesn't find it. It expects the file name to have the correct 
// signature (i.e it will not look based on the un-hashed name).
// when it finds image_tag in the .erb or .haml file, it will translate the image name to the signed //
// name and render that instead of the original image name..
//
// React needs to import the file in order to generate a signed path, which means that in order 
// to use the asset pipeline,  each new image needs to be added to the import list below, and to the 
// iconPathObj below. The hashed url is available through the exported iconPath function, while 
// the image tag is obtained using the exported imgTag function 

import ajax_loader_large from '../../../assets/images/ajax-loader-large.gif'
import caret_left from '../../../assets/images/caret-left.svg'
import caret_right from '../../../assets/images/caret-right.svg'
import arrow_up_left from '../../../assets/images/arrow-up-left.svg'
import binoculars from '../../../assets/images/binoculars.svg'
import checkin_sm from '../../../assets/images/geo-fill.svg'
import circle from '../../../assets/images/circle.svg'
import logo_noname from '../../../assets/images/logo_noname.png'
import logo from '../../../assets/images/logo.png'
import person from '../../../assets/images/person.svg'
import question_circle_fill from '../../../assets/images/question-circle-fill.svg'
import trash3 from '../../../assets/images/trash3.svg'
import globe_arrow from '../../../assets/images/globe_arrow.svg'
import chat_fill from '../../../assets/images/chat-fill.svg'

import sailboat_grn from '../../../assets/images/markers/sailboat_grn.svg'
import generic_blu from '../../../assets/images/markers/generic_blu.svg'
import hole_per from '../../../assets/images/markers/hole_per.svg'
import ramp_brn from '../../../assets/images/markers/ramp_brn.svg'
import pier_yel from '../../../assets/images/markers/pier_yel.svg'
import fuelpump_red from '../../../assets/images/markers/fuelpump_red.svg'
import club_pur from '../../../assets/images/markers/club_pur.svg'
import moorings_tur from '../../../assets/images/markers/moorings_tur.svg'
import generic_grn from '../../../assets/images/markers/generic_grn.svg'
import aton_gry from '../../../assets/images/markers/aton_gry.svg'
import anchor_blu from '../../../assets/images/markers/anchor_blu.svg'
import marina_grn from '../../../assets/images/markers/marina_grn.svg'
import unmarked_red from '../../../assets/images/markers/unmarked_red.png'
import powerb_blu from '../../../assets/images/markers/powerb_blu.svg'
import powerb_yel from '../../../assets/images/markers/powerb_yel.svg'
import powerb_red from '../../../assets/images/markers/powerb_red.svg'
import aton_blu from '../../../assets/images/markers/aton_blu.svg'
import aton_yel from '../../../assets/images/markers/aton_yel.svg'

import logimg_U  from '../../../assets/images/markers/logimg_U.png'
import logimg_C  from '../../../assets/images/markers/logimg_C.png'
import logimg_N  from '../../../assets/images/markers/logimg_N.png'

// this allows referencing the icon's asset path dynamically from a string , e.g. iconPath['binoculars']
// also convenient to export one single constant rather than one for each icon
const iconPathObj = {}
iconPathObj['sailboat_grn'] = sailboat_grn
iconPathObj['ajax_loader_large'] = ajax_loader_large
iconPathObj['caret_left'] = caret_left
iconPathObj['caret_right'] = caret_right
iconPathObj['arrow_up_left'] = arrow_up_left
iconPathObj['binoculars'] = binoculars
iconPathObj['checkin_sm'] = checkin_sm
iconPathObj['circle'] = circle
iconPathObj['globe_arrow'] = globe_arrow
iconPathObj['chat_fill'] = chat_fill
iconPathObj['logo_noname'] = logo_noname
iconPathObj['logo'] = logo
iconPathObj['person'] = person
iconPathObj['question_circle_fill'] = question_circle_fill
iconPathObj['trash3'] = trash3
iconPathObj['sailboat_grn'] = sailboat_grn
iconPathObj['aton_blu'] = aton_blu
iconPathObj['aton_yel'] = aton_yel
iconPathObj['generic_blu'] = generic_blu
iconPathObj['hole_per'] = hole_per
iconPathObj['ramp_brn'] = ramp_brn
iconPathObj['pier_yel'] = pier_yel
iconPathObj['fuelpump_red'] = fuelpump_red
iconPathObj['club_pur'] = club_pur
iconPathObj['moorings_tur'] = moorings_tur
iconPathObj['generic_grn'] = generic_grn
iconPathObj['aton_gry'] = aton_gry
iconPathObj['anchor_blu'] = anchor_blu
iconPathObj['marina_grn'] = marina_grn
iconPathObj['unmarked_red'] = unmarked_red
iconPathObj['powerb_blu'] = powerb_blu
iconPathObj['powerb_yel'] = powerb_yel
iconPathObj['powerb_red'] = powerb_red
iconPathObj['logimg_U']  = logimg_U
iconPathObj['logimg_C']  = logimg_C
iconPathObj['logimg_N']  = logimg_N

export const iconPath = icon_name => {
    return iconPathObj[icon_name]
}
export const ImgTag = props => {
    // generic image tag for icons in assets pipeline (or not)
    // IMPORTANT! icons that are not in the asset pipeline set above need to be copied 
    // to /public/assets or /public/assets/markers in production or they will not be found
    const assetPath = iconPath(props.icon_name)
    const isMarker = assetPath.includes('marker')
    return (
        <img
            src={
                iconPath(props.icon_name) ||
                `/assets/${isMarker ? 'marker/' : ''}${props.icon_name}`
            }
            width={props.size}
            className={props.class_name}
            alt={props.icon_name}
        />
    )
}
export const QuestionCircleFill = () => {
    return (
        <img
            width="13px"
            className="tooltip-question-mark"
            src={question_circle_fill}
            alt="question-circle-fill"
        />
    )
}