import React, { useRef } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Feature from 'ol/Feature'
import { Point } from 'ol/geom'
import { fromLonLat } from 'ol/proj'
import { poisStyle } from '../../../src/ol_layers'

const PlaceMap = props => {
    
    const mapElement = useRef()
    
    $('.ol-viewport').remove()

    const initialMap = new Map({
        
        target: mapElement.current,
        layers: [
            // USGS Topo
            new TileLayer({
                source: new XYZ({
                    // url:
                    //     'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
                    url: ' https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                }),
            }),
            new VectorLayer(
                {
                    source: new VectorSource({
                        features: [
                            new Feature({
                                geometry: new Point(fromLonLat([props.place.longitude, props.place.latitude])),
                                poi_count: 0,
                                type: props.place.type_enum
                            }),                           
                        ]
                    }),
                    visible: true,
                    zindex: 1,
                    style: poisStyle
                }
            )
        ],
        view: new View({
            projection: 'EPSG:3857',
            center: fromLonLat([props.place.longitude, props.place.latitude]),
            zoom: 14,
            maxZoom: 18,
            minZoom: 3,
        }),
        controls: [],
    })

    return (
        <>
            <div ref={mapElement} className="place-map">
            </div>
        </>
    )
    
}

export default PlaceMap