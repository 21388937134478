import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON'
import * as olstyle from 'ol/style'
import { Place_atts, rVal } from './helpers'
import { iconPath } from './icons_lib'
import * as olColor from 'ol/color'

export function get_layer(map, layer_name) {
    // retrieves a map layer based on the name
    var layers = map.getLayers()
    var length = layers.getLength()
    var found = false
    var l

    for (var i = 0; i < length; i++) {
        l = layers.item(i)
        if (l.get('name') === layer_name) {
            found = true
            break
        }
    }
    return found ? l : null
}

export function show_layers(map, title, show) {
    var layers = map.getLayers()
    var length = layers.getLength(),
        l

    for (var i = 0; i < length; i++) {
        l = layers.item(i)
        var lt = l.get('title')
        if (lt === title) {
            l.setVisible(show)
        }
    }
}

export function count_layers(map, title) {
    var layers = map.getLayers()
    var length = layers.getLength(),
        l
    var conta = 0

    for (var i = 0; i < length; i++) {
        l = layers.item(i)
        var lt = l.get('title')
        if (lt === title) {
            conta += 1
        }
    }
    return conta
}

export function poisStyle(feature) {
    // determines the icon to display in the map based on the class and type properties in the GeoJSON 
    // object. Feature is an object of the features array, loaded from the
    // GeoJson response when the layer is activated in displayLayer
    
    var feat_class = feature.values_.class
    var type       = feature.values_.type
    var poi_count  = feature.values_.count
    var poi_type   = feature.values_.name ? feature.values_.name.slice(0, 5) : ''
    var stinkpots   = feature.values_.stinkpots
    var iconSize = 20

    //var sog = feature.values_.sog
    var heading = feature.values_.heading
    var rotate=0

    if (feat_class == 'connection') {
        iconSize = 20

        var icon_url = iconPath(`logimg_${type}`)

        rotate = type=='N' ? heading /  180 * Math.PI : 0
        // var icon_url = iconPath(`logimg_${type}`)
    } else {
        // TODO we could handle the meteo_rep and nav_aid classes more specifically here
        // we only show one icon for nav_aids (type_enum >= 100) and the max type_enum is 19
        const type_enum = type >= 100 ? 9 : type <= 19 ? type : 19
        const icon_name = Place_atts[type_enum].icon
        var icon_url =
            poi_count && poi_count > 0
                ? poi_type == 'Ancho'
                    ? iconPath('generic_blu')
                    : iconPath('generic_grn')
                : iconPath(icon_name)
    }

    var styles_arr = [
        new olstyle.Style({
            image: new olstyle.Icon({
                src: icon_url,
                width: iconSize,
                rotation: rotate
            }),
        }),
    ]

    if (poi_count) {
        styles_arr.push(
            new olstyle.Style({
                text: new olstyle.Text({
                    anchor: [0.8, 0.8],
                    text: poi_count.toString(),
                    fill: new olstyle.Fill({
                        color: 'white',
                    }),
                }),
            })
        )
    }

    if (stinkpots) {
        styles_arr.push(
            new olstyle.Style({
                // text: new olstyle.Text({
                //     text: stinkpots.toString(),
                //     fill: new olstyle.Fill({
                //         color: 'white',
                //     }),
                // }),
                image: new olstyle.Icon({
                    anchor: [0.0, 1.1],
                    //color: [255,0,0],
                    // anchorXUnits: 'fraction',
                    // anchorYUnits: 'pixels',
                    src: iconPath('powerb_red'),
                    scale: 0.5
                  }),
            })
        )
    }

    return styles_arr
}

function aisPathStyle(feature) {
    const colors = ['','yellow', 'orange', 'red']
    const widths = [0, 0.5, 1, 1.5]
    var density = feature.values_.density
    return new olstyle.Style({
        stroke: new olstyle.Stroke({
            // color: 'white',
            color: colors[density],
            width: widths[density],
        }),
        fill: new olstyle.Fill({
            color: 'rgba(0, 0, 255, 0.2)',
          }),
    })
}

export const dnld_file = (url, file_name) => {
    // fetches the blob sent by images_controller containing the zipped gmaps
    // and downloads it through the browser
    $('#ajax-loader').show()
    fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = file_name
            // TODO handle error responses
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            var alert_msg = 'Your file has downloaded.'
            if (file_name.includes('.zip')) {
                alert_msg += '\n\nSee information in README.TXT'
            }
            $('#ajax-loader').hide()
            alert(alert_msg)
        })
        .catch(() => alert('oh no!'))
}

const refreshLayer = (source, url) => {
    source.setUrl(url)
    source.refresh()
}

const hideSpinner = source => {
    source.on('change', evt => {
        if (evt.target.getState() === 'ready') {
            $('#ajax-loader').hide()
        }
    })
}
export const layerUrl = (apiPath, mapCorners, zoom) => {
    const [ll, ur] = mapCorners
    return `${apiPath}&x1=${rVal(ll[0],4)}&y2=${rVal(ur[1],4)}&x2=${rVal(ur[0],4)}&y1=${rVal(ll[1],4)}&zoom=${zoom}`
}

export const displayLayer = (
    map,
    zoom,
    mapCorners,
    layerName,
    apiPath,
    zIndex,
    dataCallBack
) => {
    
    // if (layerName != 'AisPathLayer') {return}

    let layer = get_layer(map, layerName)


    const [ll, ur] = mapCorners
    let url = layerUrl(apiPath, mapCorners, zoom)
    let source
    
    if (!layer) {
        
        source = new VectorSource({
            url: url,
            format: new GeoJSON(),
        })
        source.setProperties({ extent: [ll, ur].flat() })

        if (dataCallBack) {
            source.on('change', function(evt) {
                const tgt_source = evt.target
                if (tgt_source.getState() === 'ready') {
                    dataCallBack(tgt_source.getFeatures())
                }
            })
        }

        hideSpinner(source)

        const layerOptions = {
            source: source,
            visible: true,
            zindex: zIndex,
            name: layerName,
            title: layerName,
        }
        if (layerName == 'AisPathLayer'){
            layerOptions.style = aisPathStyle
        }
        else if (layerName != 'OutlineLayer') {
            layerOptions.style = poisStyle
        }

        // I think this is where the features object is read from the GeoJSON response?
        layer = new VectorLayer(layerOptions)
        map.addLayer(layer)
    } else {
        layer.setZIndex(zIndex)
        // I think this is where the features object is read from the GeoJSON response?
        source = layer.getSource()
        refreshLayer(source, url)
    }
    $('#ajax-loader').show()

    return layer
}
