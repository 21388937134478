import React from 'react'
import { strToDate, userThumbnail, addSlash } from '../../../src/helpers'
import PostForm from '../../Post/PostForm'
import UserContext from '../../../store/user-context'
import { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { ImgTag } from '../../../src/icons_lib'
import { Link } from 'react-router-dom'
import CCLicense from '../../CCLicense'

const UserFeed = props => {
    const userCtx = useContext(UserContext)

    const [posts, setPosts] = useState()

    const photoItems = allItems => {
        // we only want posts that contain an image
        return allItems.filter(item => {
            return item.provider != 'flickr'
        })
    }

    const refreshFeed = new_item => {
        posts.unshift(new_item)
        // IMPORTANT could not get the useState assignmet to rerender. It turns out that changing the *contents* of the array
        // does not get recognized as a change in state. One solution is to spread the array (or to create a new one). This
        // creates a new reference https://stackoverflow.com/questions/56266575/why-is-usestate-not-triggering-re-render
        setPosts([...posts])
    }

    const handleDelete = post => {
        console.log('handle delete', post)

        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

        if (confirm('Confirm post delete?') == true) {
            const url_str = `/api/v1/posts/${post.id}`
            axios
                .delete(url_str)
                .then(resp => {
                    const r = posts.filter(i => i !== post)
                    console.log('r: ', r)
                    setPosts(r)
                    props.callBack('feed', -1)
                    //setCloseMenu(true)
                })
                .catch(resp => console.log('Error in deleting post', resp))
        } else {
            console.log('post delete canceled ')
        }
    }

    useEffect(
        () => {
            const photoPosts = photoItems(props.posts)
            props.callBack('feed', photoPosts && photoPosts.length)
            setPosts(photoPosts)
        },
        // IMPORTANT when assigning the initial value of a state variable to props,
        // need to trigger useEffect with props
        [props.posts]
    )

    return (
        <>
            <ul>
                {posts &&
                    posts.map((post, index) => {
                        return (
                            <li className="list-group" key={index}>
                                <div className="row">
                                    <div className="col-2">
                                        <img
                                            className="img-thumbnail me-1"
                                            width="30px"
                                            src={userThumbnail(
                                                post.user_thumb_url
                                            )}
                                            alt="squiddio-user"
                                        />
                                        <Link to={`/userview/${post.user_id}`}>
                                            {post.user_name}
                                        </Link>
                                    </div>
                                    <div className="col">
                                        {post.updated_at &&
                                            strToDate(post.updated_at)}
                                    </div>
                                    <div className="col">{post.comment}</div>
                                    <div className="col">
                                        {post.thumb_url && (
                                            <img
                                                width="100px"
                                                src={addSlash(post.thumb_url)}
                                                alt={'post' + post.id}
                                                className=" img-thumbnail"
                                            />
                                        )}
                                    </div>
                                    <div className="col m-1">
                                        {post.provider != 'squiddio' &&
                                            userCtx.loggedIn &&
                                            post.user_id == userCtx.user.id && (
                                                <button
                                                    className="btn btn-sm"
                                                    onClick={() => {
                                                        handleDelete(post)
                                                    }}
                                                >
                                                    <ImgTag
                                                        icon_name="trash3"
                                                        size={15}
                                                    />
                                                </button>
                                            )}
                                    </div>
                                </div>
                            </li>
                        )
                    })}
            </ul>

            <div className="container text-center border rounded p-2">
                <PostForm callBack={refreshFeed} place={props.place}></PostForm>
            </div>

            <CCLicense />
        </>
    )
}

export default UserFeed
