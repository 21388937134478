// NMEA vessel charts
import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { GoogleCharts } from 'google-charts'
import { drawColumnChart, drawBubbleChart } from '../../src/draw_charts'

const Chart = props => {
    // Unlike the case for placeview, chart objects for vessels are generated by the controller in the show method
    // and do not need to be requested separately, to avoid a double hit to the database.

    // if there is no chart data , show a hero with a call to action
    if (!props.charts) {
        return (
            <div className="vess-data text-center mt-5 border rounded">
                <div className="vess-data-hero p-3">
                    <p className=" h5">
                        Monitor your boat's sensor data remotely with the OpenCPN Squiddio Plugin
                    </p>
                    <p>
                        Find out <a style={{color: 'white'}} href="/squiddio_pi">how</a>
                    </p>
                </div>
            </div>
        )
    }

    const chartData = props.charts

    GoogleCharts.load(drawColumnChart)
    GoogleCharts.load(drawBubbleChart)

    const renderCharts = () => {
        // need to wait until the libraries are loaded before calling the functions below
        $('#ajax-loader').show()
        setTimeout(function() {
            $('#ajax-loader').hide()
            drawColumnChart(
                chartData.wsp.mnth_ary,
                3,
                '30-day Apparent Wind Speed (MWV)',
                'Day',
                'Knots',
                'wsp_month'
            )
            drawColumnChart(
                chartData.wsp.day_ary,
                3,
                '24-hour Apparent Wind Speed (MWV)',
                'Time (GMT)',
                'Knots',
                'wsp_day'
            )
            console.log('wang.mnth.ary: ', chartData.wang.mnth_ary)
            drawBubbleChart(
                chartData.wang.mnth_ary,
                '30-day Apparent Wind Direction and Speed (MWV)',
                'wang_month'
            )
            drawColumnChart(
                chartData.wang.day_ary,
                3,
                '24-hourApparent Wind Direction (MWV)',
                'Time (GMT)',
                'Deg. App.',
                'wang_day'
            ),
                drawColumnChart(
                    chartData.dbt.day_ary,
                    3,
                    '24-hour Water Depth (DBT)',
                    'Time (GMT)',
                    'Meters',
                    'depth_day'
                )
        }, 5000) // increase this if you get a gcharts constructor error or the like in console
    }

    return (
        <div className="border mt-3 p-3 rounded">
            <h5 className="m-3 ">NMEA Vessel Analytics</h5>
            <div className="row m-3">
                <div id="wsp_day" className="col-md-3 col-lg-3"></div>
                <div id="wsp_month" className="col-md-3 col-lg-3"></div>
                <div id="wang_day" className="col-md-3 col-lg-3"></div>
                <div id="wang_month" className="col-md-3 col-lg-3"></div>
            </div>
            <div className="row m-3">
                <div id="depth_day" className="col-md-3 col-lg-3"></div>
            </div>
            {renderCharts()}
        </div>
    )
}

export default Chart
