import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { vesselThumbnail } from '../../../src/helpers'
import { Link } from 'react-router-dom'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

const BasedHere = props => {
    const [vessels, setVessels] = useState([])

    useEffect(() => {
        const vessels_url_str = `/api/v1/vessels?place_id=${props.place.id}`
        if (props.place.id) {
            axios
                .get(vessels_url_str)
                .then(resp => {
                    setVessels(resp.data)
                    props.callBack('vessels', resp.data && resp.data.length)
                })
                .catch(resp =>
                    console.log(
                        'Error in retrieving vessels based at place',
                        resp
                    )
                )
        }
    }, [props.place])

    return (
        <>
            <h6 className="bg-secondary text-white p-1">
                These vessels are based here
            </h6>
            <ul className="list-group">
                <li className="list-group-item p-1">
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col">Name</div>
                        <div className="col-1">LOA (mt)</div>
                        <div className="col-2">MMSI</div>
                        <div className="col-1">Flag</div>
                        <div className="col">User</div>
                    </div>
                </li>
                {vessels.map((vessel, index) => {
                    return (
                        <li className="list-group-item" key={index}>
                            <div className="row">
                                <div className="col-1 text-center">
                                    <img
                                        className="img-thumbnail"
                                        src={vesselThumbnail(vessel)}
                                        alt={vessel.vessel_type}
                                        width={50}
                                    />
                                </div>
                                <div className="col">
                                    <Link to={`/vesselview/${vessel.id}`}>
                                        {vessel.name}
                                    </Link>
                                </div>
                                <div className="col-1">{vessel.loa}</div>
                                <div className="col-2">{vessel.mmsi}</div>
                                <div className="col-1">
                                    {vessel.country_registration && getUnicodeFlagIcon(vessel.country_registration)}
                                </div>
                                <div className="col">
                                    <Link to={`/userview/${vessel.user_id}`}>
                                        {vessel.user_name}
                                    </Link>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

export default BasedHere
