import React from 'react'
import countryList from 'react-select-country-list'
import { Link } from 'react-router-dom'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

const VesselShow = props => {
    const vessel = props.vessel

    return (
        <div className="row">
            <div className="col">
                <ul className="list-group shadow">
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Type:</div>
                            <div className="col">
                                <b>{vessel.vessel_type}</b>
                            </div>
                        </div>
                    </li>
                    {vessel.user_id && (
                        <li className="list-group-item">
                            <div className="row">
                                <div className="col-3 text-muted">User:</div>
                                <div className="col">
                                    <Link to={`/userview/${vessel.user_id}`}>
                                        {vessel.user_name}
                                    </Link>
                                </div>
                            </div>
                        </li>
                    )}
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">MMSI:</div>
                            <div className="col">{vessel.mmsi}</div>
                        </div>
                    </li>

                    {/* <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Name:</div>
                            <div className="col">{vessel.name}</div>
                        </div>
                    </li> */}

                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Description:</div>
                            {vessel.description && (
                                <div
                                    className="col"
                                    dangerouslySetInnerHTML={{
                                        __html: vessel.description,
                                    }}
                                />
                            )}
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Home port:</div>
                            <div className="col">
                                {vessel.place_id && (
                                    <Link to={`/placeview/${vessel.place_id}`}>
                                        {vessel.place_name}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">
                                Checked in at:
                            </div>
                            <div className="col">
                                {vessel.checkin_place_id && (
                                    <Link
                                        to={`/placeview/${vessel.checkin_place_id}`}
                                    >
                                        {vessel.checkin_place_name}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Country:</div>
                            <div className="col">
                                {vessel.country_registration && 
                                    <div>
                                        {countryList().getLabel(
                                            vessel.country_registration
                                        )}

                                        <span className="m-2" />
                                        {getUnicodeFlagIcon(vessel.country_registration)}
                                    </div>
                                }
                            </div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Loa (mt):</div>
                            <div className="col">{vessel.loa}</div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Make:</div>
                            <div className="col">{vessel.make}</div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Model:</div>
                            <div className="col">{vessel.model}</div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Year:</div>
                            <div className="col">{vessel.year_make}</div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Draft (mt):</div>
                            <div className="col">{vessel.draft}</div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Beam (mt):</div>
                            <div className="col">{vessel.beam}</div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">IMO:</div>
                            <div className="col">{vessel.imo}</div>
                        </div>
                    </li>
                    <li className="list-group-item">
                        <div className="row">
                            <div className="col-3 text-muted">Call Sign:</div>
                            <div className="col">{vessel.call_sign}</div>
                        </div>
                    </li>
                    {vessel.user && (
                        <li className="list-group-item">
                            <div className="row">
                                <div className="col-3 text-muted">Owner:</div>
                                <div className="col">{vessel.user}</div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default VesselShow
