import React from 'react'
import {createContext, useState} from 'react'

// default layer selection
const defaultLayers = {
    pois: true,
    satMaps: false,
    stinkPots: false,
    buoys: false,
    navAids: false,
    aisPaths: false
}

// center of initial map view prior to browser geolocation
const defaultView = {
    longitude: 4.5,
    latitude: 43.5,    
    // longitude: -118.27,
    // latitude: 33.73,
    default: true
}

const UserContext = createContext({
    user: {},
    loggedIn: false,
    features: [],
    lastCoords: {},
    lastZoom: 0,
    activeLayers: {},
    updUser: (userStatus) => {}, // this is only here to help with autocomplete
    updFeatures: (features) => [],
    updView: (coords, zoom) => {},
    updActiveLayers: (layers) => {}
})

export function UserContextProvider(props) {

    const [currentUser,  setCurrentUser] = useState({})
    const [loginStatus,  setLoginStatus] = useState(false)
    const [showIntro,  setShowIntro] = useState(false)
    const [features, setFeatures] = useState([])
    //const [currentCooords,  setCurrentCoords] = useState(defaultView)
    const [currentCooords,  setCurrentCoords] = useState(defaultView)
    const [currentZoom,  setCurrentZoom] = useState(0)
    const [layers,  setLayers] = useState(defaultLayers)

    function updUserHandler(userStatus)  {
        setCurrentUser(userStatus.user)
        setLoginStatus(userStatus.logged_in)
        setShowIntro(userStatus.show_intro)
    }

    function updFeaturesHandler(features) {
        //console.log('updFeatures: ', features)
        setFeatures(features)
    }

    function updViewHandler(coords, zoom) {
        setCurrentCoords(coords)
        setCurrentZoom(zoom)
    }

    function updLayersHandler(layers)  {
        setLayers(layers)
    }

    const context = {
        user: currentUser, 
        loggedIn: loginStatus, 
        showIntro: showIntro, 
        features: features,
        lastCoords: currentCooords,
        lastZoom: currentZoom,
        activeLayers: layers, 
        updUser: updUserHandler,
        updFeatures: updFeaturesHandler,
        updView: updViewHandler,
        updActiveLayers: updLayersHandler
    }

    return <UserContext.Provider value={context}>
        {props.children} 
    </UserContext.Provider>
}

export default UserContext 