import React from 'react'
import ReactDom from 'react-dom'
import PlaceForm from '../components/Place/PlaceForm';
import {fromLonLat, toLonLat, transform} from 'ol/proj';

// this is necessary to expose the handleContexMenuEvent so it is available within the userscript using the onclick
// in add_menu_item. https://stackoverflow.com/questions/17378199/uncaught-referenceerror-function-is-not-defined-with-onclick
window.handleContexMenuEvent = handleContexMenuEvent;


import { get_layer, count_layers} from "./ol_layers";
const min_tile_zoom = 12;
const min_pois_zoom = 0;
const ml_zoom = 14, min_cont_zoom = 5;  // todo these need to be made consistent with constants in environment.rb

function add_menu_item(item_id, item_desc, item_count, lng, lat) {
    var count_str = (item_count && item_count > 0) ? `(${item_count})` : ``
    return `<div class="menuItem" onclick="handleContexMenuEvent(\'${item_id}\', ${lng}, ${lat});"> ${item_desc} ${count_str}</div>`
}

export function openContextMenu(map, zoom,  e) {
    $('.contextMenu').remove();
   
    const pix = map.getEventPixel(e);
    const point = map.getCoordinateFromPixel(pix);
    const lonLat = toLonLat(point); 
    const lng = lonLat[0];
    const lat = lonLat[1];
    
    var place_layer = get_layer(map, 'PlaceLayer');
    var pois_count = place_layer.getSource().getFeatures().length;
    var raster_count = count_layers(map, 'RasterLayer');
    
    // create a string containing the HTML for the context menu
    var menu_string = '<div class="contextMenu" style=" top: ' + e.y + 'px; left:' + e.x + 'px;">';
    menu_string += add_menu_item('addPoi', 'Add a Point of Interest at this location', null, lng, lat);
    //menu_string += '<br>';
    if (pois_count > 0 && zoom > min_pois_zoom) {menu_string += add_menu_item('dnldPois', 'Points of Interest', pois_count, lng, lat);}
    if (raster_count > 0  && zoom > min_tile_zoom) {
        menu_string += add_menu_item('dnldGmapTileKap',  'Google Maps Tiles (Kaps)', raster_count);
    }
    menu_string += '</div>'
    
    $('.map').append(menu_string);
}

export function handleContexMenuEvent(option, lng, lat) {
    $('.contextMenu').remove();

    const place_div = document.getElementById('place')
    ReactDom.render(<PlaceForm coords={[lng, lat]}/>, place_div  )

    if (option == 'addPoi' ) {
        console.log('addPoi')
    } 
}