import React, { useEffect, useState, useContext, Fragment } from 'react'
import axios from 'axios'
import UserContext from './store/user-context'
import UserVessel from './components/Vessel/UserVessel'
import FollowedVessel from './components/Vessel/FollowedVessel'
import UserEdit from './components/User/UserEdit'
import UserPhoto from './components/User/UserPhoto'

function Profile() {
    const userCtx = useContext(UserContext)
    const [user, setUser] = useState({})

    // retrieve user profile and login status
    useEffect(() => {
        axios
            .get('/api/v1/users/logged_in', { withCredentials: true })
            .then(response => {
                console.log('response data', response.data)
                userCtx.updUser(response.data)
                setUser(response.data.user)
            })
            .catch(error => {
                console.log('error in checking login status')
            })
    }, [])

    return (
        <Fragment>
            <h4 className='m-3'>User profile: {userCtx.user && user.email}</h4>
            <div className="row">
                <div className="col-md border shadow rounded p-3 m-3">
                    <UserEdit user={user} />
                </div>
                
                <div className="col-md border shadow rounded p-3 m-3">
                    <UserPhoto user={user} />
                </div>
            </div>

            <h5>User's vessels</h5>
            <UserVessel />

            <h5>Followed vessels</h5>
        
            <FollowedVessel />
            
        </Fragment>
    )
}

export default Profile
