import React, { useState, useEffect, Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { placeThumbnail } from '../../../src/helpers'

const PopPlace = props => {
    return (
        <li className="list-group-item">
            <div className="row">
                <div className="col-2">
                    <img
                        width="60px"
                        className="img-thumbnail"
                        src={placeThumbnail(props.place)}
                        alt={props.place.id}
                    />
                </div>
                <div className="col">
                    <Link to={`/placeview/${props.place.id}`}>
                        {props.place.name}{' '}
                    </Link>
                </div>
                <div className="col">{props.place.popularity}</div>
            </div>
        </li>
    )
}

export default PopPlace
