import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { ImgTag } from '../../src/icons_lib'
import UserContext from '../../store/user-context'

const VesselPhotos = props => {
    
    const vessel = props.vessel
    const [file, setFile] = useState(null)
    const [photos, setPhotos] = useState([])
    const userCtx = useContext(UserContext)

    useEffect(() => {
        setPhotos(vessel.photo_urls || [])
    }, [props.vessel])

    const handleSubmit = e => {
        e.preventDefault()
        // formData includes the multipart parameters to upload a file
        const photo = new FormData()
        photo.append('vessel[photo][image]', file)

        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

        axios
            .patch(`/api/v1/vessels/${vessel.id}`, photo)
            .then(resp => {
                //TODO can't get this to clear file in the form using the Value property on the field, as I do for comment
                setFile(null)
                const new_photo_url = resp.data.new_photo_url
                setPhotos([...photos, new_photo_url])
            })
            .catch(resp => {
                console.log('fallito! ', resp.data)
            })
    }
    const handleDelete = url => {
        const urlSplit = decodeURIComponent(url).split('/')
        const photo_id = urlSplit[urlSplit.length - 3]
        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

        if (confirm('Confirm photo delete?') == true) {
            const url_str = `/api/v1/photos/${photo_id}`
            axios
                .delete(url_str)
                .then(resp => {
                    // tried this with .filter() but could not get it to work
                    const newArr = removeUrl(photos, url)
                    setPhotos([...newArr])
                })
                .catch(resp => console.log('Error in deleting photo', resp))
        } else {
            console.log('photo delete canceled ')
        }
    }

    const subArrays = urlArr => {
        const chunkSize = 3
        const chunks = []
        for (let i = 0; i < urlArr.length; i += chunkSize) {
            const chunk = urlArr.slice(i, i + chunkSize)
            chunks.push(chunk)
        }
        return chunks
    }
    const removeUrl = (arr, url) => {
        for (var i = arr.length - 1; i >= 0; i--) {
            if (arr[i] === url) {
                arr.splice(i, 1)
            }
        }
        return arr
    }

    const ImgRow = props => {
        const urlRow = props.urlRow

        return urlRow.map((url, index) => {
            return (
                <div key={index} className="col">
                    <img
                        className="img-thumbnail"
                        width="200px"
                        src={'/' + url}
                        alt={vessel.name}
                        key={index}
                    />
                    {userCtx.loggedIn && vessel.user_id == userCtx.user.id && (
                        <button
                            className="btn btn-sm"
                            onClick={() => {
                                handleDelete(url)
                            }}
                        >
                            <ImgTag icon_name="trash3" size={15} />
                        </button>
                    )}
                </div>
            )
        })
    }

    return (
        <div className='p-3'>
            <h5>Photos</h5>
            {photos &&
                subArrays(photos).map((urlRow, index) => {
                    return (
                        <div key={index} className="row">
                            <ImgRow urlRow={urlRow} />
                        </div>
                    )
                })}

            <div className="row p-2"></div>

            {props.userCtx.loggedIn && props.userCtx.user.id == vessel.user_id && (
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="field m-2">
                            <label htmlFor="" className="form-label me-2">
                                Select an image to upload:
                            </label>
                            <input
                                className="form-control"
                                type="file"
                                onChange={e => {
                                    setFile(e.target.files[0])
                                }}
                                required
                            />
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-3 text-center">
                                <button
                                    className="btn btn-primary m-2"
                                    type="submit"
                                >
                                    Upload image
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div >
    )
}

export default VesselPhotos
