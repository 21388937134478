import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import XYZ from 'ol/source/XYZ'
import Feature from 'ol/Feature'
import { Point } from 'ol/geom'
import { defaults as defaultInteractions } from 'ol/interaction'

import { fromLonLat, transform } from 'ol/proj'
import {Link } from 'react-router-dom'

import { poisStyle } from '../src/ol_layers'
import axios from 'axios'

function NavAidEdit() {
    const location = useLocation()
    const [nav_aid, setNavAid] = useState(location.state.nav_aid)
    const [map, setMap] = useState()
    const [lngLat, setLngLat] = useState([nav_aid.longitude, nav_aid.latitude])
    const mapElement = useRef()
    const mapRef = useRef()
    mapRef.current = map

    const nav_aid_id = location.state.id
    let item

    useEffect(() => {
        $('.ol-viewport').remove()

        // create map
        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                // USGS Topo
                new TileLayer({
                    source: new XYZ({
                        url:
                            'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
                    }),
                }),
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: fromLonLat(lngLat),
                zoom: 14,
                maxZoom: 16,
                minZoom: 14,
            }),
            interactions: defaultInteractions({ dragPan: false }),
            controls: [],
        })

        item = new Feature({
            geometry: new Point(fromLonLat(lngLat)),
            poi_count: 0,
            type: nav_aid.type_enum,
        })

        var poiLayer = new VectorLayer({
            source: new VectorSource({
                features: [ item],
            }),
            visible: true,
            zindex: 1,
            style: poisStyle,
            name: 'NavAidLayer',
            title: 'NavAidLayer',
        })

        initialMap.addLayer(poiLayer)

        // set map onclick handler
        initialMap.on('click', handleMapClick)

        // save map and vector layer references to state
        setMap(initialMap)
    }, [])

    const handleMapClick = event => {
        const coords = event.coordinate
        const lngLatAr = transform(coords, 'EPSG:3857', 'EPSG:4326')
        nav_aid.latitude = lngLatAr[1]
        nav_aid.longitude = lngLatAr[0]
        console.log(nav_aid)
        item.getGeometry().setCoordinates(coords)
        setLngLat(lngLatAr)
        console.log('lngLat: ', lngLat)
    }

    const handleChange = e => {
        e.preventDefault()
        setNavAid(Object.assign({}, nav_aid, { [e.target.name]: e.target.value }))
    }

    const handleSubmit = e => {
        e.preventDefault()

        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

        axios
            .patch(`/api/v1/nav_aids/${nav_aid_id}`, { nav_aid })
            .then(resp => {
                // TODO there must be a better way to reset the values to null in the state
                alert('NavAid updated')
            })
            .catch(resp => {
                alert('error in updating nav_aid')
            })
    }

    return (
        <>
            <div>
                NavAid Edit Page
                <div>Latitude: {lngLat[1]}</div>
                <div>Longitude: {lngLat[0]}</div>
                <form onSubmit={handleSubmit}>
                    <div className="field" onChange={handleChange}>
                        <input
                            type="text"
                            name="name"
                            nav_aidholder="e.g. Azz navaid"
                            defaultValue={nav_aid.name}
                        />
                    </div>
                    <div className="field" onChange={handleChange}>
                        <input
                            type="text"
                            name="city"
                            nav_aidholder="Sausalito"
                            defaultValue={nav_aid.city}
                        />
                    </div>
                    <div className="field">
                        <input
                            type="text"
                            name="description"
                            nav_aidholder="Very cool nav_aid"
                            defaultValue={nav_aid.description}
                        />
                    </div>
                    <button type="submit">Update nav_aid</button>
                </form>
            </div>
            <div ref={mapElement} className="edit-map"></div>
            <Link to="/">Back to map</Link>
        </>
    )
}

export default NavAidEdit
