import React, {useEffect, useState, useContext} from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Home from './pages/Home'
import PlaceView from './pages/PlaceView'
import PlaceEdit from './pages/PlaceEdit'
import VesselView from './pages/VesselView'
import NavAidView from './pages/NavAidView'
import NavAidEdit from './pages/NavAidEdit'
import UserView from './pages/UserView'
import UserContext from './store/user-context'


function App() {

    const userCtx = useContext(UserContext)
    const navigate = useNavigate()

    // retrieve user profile and login status
    useEffect (() => {
      axios.get('/api/v1/users/logged_in', {withCredentials: true})
      .then( response => {
          //console.log('logged in? ', response.data)
          userCtx.updUser(response.data)
        }
      )
      .catch(error => {console.log('error in checking login status')})
    }
    , [])

    return(
        <Routes>
            <Route exact path="/" element={<Home nav= {navigate} />} > </Route>
            <Route path="/placeview/:id" element={<PlaceView />} ></Route>
            <Route path="/placeview" element={<PlaceView />} ></Route>
            <Route path="/placeedit" element={<PlaceEdit />} ></Route>
            <Route path="/vesselview/:id" element={<VesselView />} ></Route>
            <Route path="/vesselview" element={<VesselView />} ></Route>
            <Route path="/userview/:id" element={<UserView />} ></Route>
            <Route path="/nav_aidview" element={<NavAidView />} ></Route>
            <Route path="/nav_aidedit" element={<NavAidEdit />} ></Route>
        </Routes>
    )
}

export default App