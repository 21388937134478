import React from "react";
import { addSlash } from "../../../src/helpers";

const CarouselItem = ({ item, width }) => {
  return (

    <div className="carouzel-item" style={{ width: width }} >
      <img className="carouzel-img" src={addSlash(item.image_url)} />
    </div>
  );
};
export default CarouselItem