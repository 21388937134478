import React, {
    Fragment,
    useState,
    createContext,
    useEffect,
    useContext,
} from 'react'
import Search from '../components/Search'
import PopPlaces from '../components/Place/PopPlaces/PopPlaces'
import Events from '../components/Events/Events'
import MapWrapper from '../components/Map/MapWrapper'
import Legend from '../components/Map/Legend'
import UserContext from '../store/user-context'
import IntroModal from './IntroModal'
//import { GoogleCharts } from 'google-charts'
//import { drawPieChart, drawColumnChart } from '../../src/draw_charts'
//import { drawPieChart, drawColumnChart } from '../src/draw_charts'

const Home = props => {
    //GoogleCharts.load(drawPieChart)
    //GoogleCharts.load(drawColumnChart)

    const userCtx = useContext(UserContext)
    const activeLayers = createContext()
    const [timeOutId, setTimeOutId] = useState(null)
    
    document.title = 'Squidd.io - Marinas and anchorages near me - Traffic and weather - Download satellite maps'
    document.getElementsByTagName('meta')["description"].content = "Discover and rate the world's best marinas and anchorages. Download them to your OpenCPN charts. Follow favorite vessels around the world. Download satellite maps for OpenCPN and other chart plotters. Check weather at your marina or anchorage. Find where boats and yachts are moored or anchored.";

    useEffect(() => {
        //displays the modal if the user is not logged in, but waits until
        //userCtx has been updated with the login status
        if(userCtx.showIntro) {
            const timeId=setTimeout(() => {$('#intro-modal').modal('show')}, 2000)
            setTimeOutId(timeId)
        }
        else {
            clearTimeout(timeOutId)
            setTimeOutId(null)
            $('#intro-modal').modal('hide')
        }
    }, [userCtx.showIntro])

    return (
        <div className="container">
            <IntroModal />
            <div className="row mt-1 relative-container">
                <Search />
            </div>

            <div className="row mb-3">
                <div className="col rounded">
                    <MapWrapper features={[]} />
                    <Legend />
                </div>
            </div>

            <div className="row">
                <div className="col-md border rounded m-2 p-2 shadow">
                    <div id="pop_places" className="pop_places">
                        <PopPlaces />
                    </div>
                </div>
                <div className="col-md border rounder m-2 p-2 shadow">
                    <Events />
                </div>
            </div>
        </div>
    )
}

export default Home
