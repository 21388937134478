import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { CountryOptions, VesselTypeOptions, delFields } from '../../src/helpers'

const VesselEdit = props => {
    const [vessel, setVessel] = useState({})
    const [placeName, setPlaceName] = useState('')
    const [matchPlaces, setMatchPlaces] = useState([])

    const ulPlaceRef = useRef()
    const inputRef = useRef()

    useEffect(() => {
        
        // let country_code = 'US'
        // if (!props.vessel.country_registration) {
        //     // initialize country code based on IP address
        //     axios.get('https://ipapi.co/json/').then((response) => {
        //         country_code = response.data.country_code;
        //         props.vessel.country_registration = country_code
        //     }).catch((error) => {
        //         console.log(error);
        //     });
        // }

        // I can't get the above to alter the content of country_registration, so the pull down defaults to Afghanistan
        if (!props.vessel.country_registration ) {props.vessel.country_registration= 'US'}

        setVessel(props.vessel)
    }, [props])

    useEffect(() => {
        // set bounce timer for search field
        const timeOutId = setTimeout(() => getPlaceCandidates(placeName), 500)
        return () => clearTimeout(timeOutId)
    }, [placeName])

    const handleChange = e => {
        e.preventDefault()
        setVessel(
            Object.assign({}, vessel, { [e.target.name]: e.target.value })
        )
    }
    function getPlaceCandidates(partialName) {
        // retrieve list of vessel candidates based on partial name
        if (partialName.length > 3) {
            axios
                .get(`/api/v1/places/?partial_name=${partialName}`)
                .then(response => {
                    setMatchPlaces(response.data)
                })
                .catch(error => {
                    console.log('error in retrieving candidate places')
                })
        }
    }

    function showPlaceCandidates(list) {
        // display picklist of vessel candidates
        return list.map((listItem, index) => {
            return (
                <button
                    type="button"
                    key={index}
                    onClick={e => {
                        inputRef.current.value = listItem.name
                        ulPlaceRef.current.style.display = 'none'
                        const places = matchPlaces.filter(
                            p => p.name == listItem.name
                        )
                        //setPlaceName(places[0].name)
                        vessel.place_name = places[0].name
                        vessel.place_id = listItem.id
                        setVessel(vessel)
                    }}
                    className="list-group-item list-group-item-action"
                >
                    <div className="row">
                        <div className="col-4">{listItem.name}</div>
                        <div className="col-4">{listItem.address}</div>
                        <div className="col-3">{listItem.city}</div>
                        <div className="col-1">{listItem.country_code}</div>
                    </div>
                </button>
            )
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        const csrfToken = document.querySelector('[name=csrf-token').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
        // clone a new object with the spread operator to preserve fields prior to deleting
        const new_vess = { ...vessel }

        delFields(
            [
                'place_name',
                'user_name',
                'checkin_place_id',
                'checkin_place_name',
                'photo_urls',
                'thumb_url',
                'thumb_urls',
                'last_position',
                'charts'
            ],
            vessel
        )

        axios
            .patch(`/api/v1/vessels/${vessel.id}`, { vessel })
            .then(resp => {
                alert('vessel updated')
                props.callBack(new_vess)
            })
            .catch(resp => {
                alert(`Error: ${resp.response.data.mmsi}. Vessel not updated`)
                console.log('Error in updating vessel', resp)
            })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-2 text-muted p-3">MMSI:</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="text"
                            name="mmsi"
                            value={vessel.mmsi}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Name:</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder="e.g. Bella Vita"
                            value={vessel.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Description:</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="text"
                            name="description"
                            placeholder="e.g. Well fitted, fully equipped, ocean going vessel. Deep keel, two-cabin version"
                            value={vessel.description}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Type:</div>
                <div className="col">
                    <div className="form-field">
                        <select
                            className="form-control"
                            type="select"
                            name="vessel_type"
                            value={vessel.vessel_type}
                            onChange={handleChange}
                        >
                            <VesselTypeOptions />
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Home port:</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="text"
                            name="place_id"
                            placeholder={vessel.place_name}
                            ref={inputRef}
                            onChange={event =>
                                // setting the partial vessel name also triggers the effect that retrieves vessel candidates
                                // and fires the pulldown below
                                setPlaceName(event.target.value)
                            }
                        />
                    </div>
                    <div>
                        {placeName && (
                            // place candidates pull down
                            <ul
                                id="results"
                                className="list-group"
                                ref={ulPlaceRef}
                            >
                                {showPlaceCandidates(matchPlaces)}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Country:</div>
                <div className="col">
                    <div className="form-field">
                        <select
                            className="form-control"
                            type="select"
                            name="country_code"
                            value={vessel.country_registration}
                            onChange={handleChange}
                        >
                            <CountryOptions />
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Loa (mt):</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="number"
                            name="loa"
                            value={vessel.loa}
                            step="any" // this allows fractional numbers
                            onChange={handleChange}
                            min={1}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Make:</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="text"
                            name="make"
                            placeholder="e.g. Beneteau"
                            value={vessel.make}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Model:</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="text"
                            name="model"
                            placeholder="e.g. 43"
                            value={vessel.model}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Year make:</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="number"
                            name="year_make"
                            placeholder="e.g. 2006"
                            value={vessel.year_make}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Draft (mt):</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="number"
                            name="draft"
                            step="any"
                            min={0}
                            value={vessel.draft}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Beam (mt):</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="number"
                            name="beam"
                            min={0}
                            step="any"
                            value={vessel.beam}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">IMO:</div>
                <div className="col">
                    <div className="form-field">
                        <input
                            className="form-control"
                            type="text"
                            name="imo"
                            value={vessel.imo}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 text-muted p-3">Call sign:</div>
                <div className="col">
                    <div className="form-field" onChange={handleChange}>
                        <input
                            className="form-control"
                            type="text"
                            name="call_sign"
                            value={vessel.call_sign}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>

            <button type="submit" className="btn btn-primary m-3">
                Update vessel data
            </button>
        </form>
    )
}

export default VesselEdit
