//https://github.com/harakisgeorge/carouselreact
// had to change css class names from carousel to carouzel because of conflicts with bootstrap
import React, { useState, useEffect, Fragment } from 'react'
import CarouselItem from './CarouselItem'
import { ImgTag } from '../../../src/icons_lib'
import { truncate_with_dots } from '../../../src/helpers'

const Images = props => {
    const [activeIndex, setActiveIndex] = useState(0)

    // we only want posts that contain an image

    var items = props.posts.filter(item => {
        return item.image_url && item //{id: item.id , image_url: item.image_url, comment: item.comment}
    })
    items = items && items.slice(0,12)

    const updateIndex = newIndex => {
        // pointer to the current carousel image
        if (newIndex < 0) {
            newIndex = 0
        } else if (newIndex >= items.length) {
            newIndex = items.length - 1
        }
        setActiveIndex(newIndex)
    }
    return (
        <div className="carouzel">
            <div
                className="inner"
                style={{ transform: `translate(-${activeIndex * 100}%)` }}
            >
                {items.map(item => {
                    return (
                        <CarouselItem
                            item={item}
                            width={'100%'}
                            key={item.id}
                        />
                    )
                })}
            </div>
            {items[activeIndex] &&
                <div className='image-label'>
                    {truncate_with_dots(items[activeIndex].comment,65)}  
                    {items[activeIndex].provider == 'flickr' &&
                        <span className="to_right small-text me-2"> © Copyright Flickr <img src={"/assets/flickr_logo_sm.png"} /></span>
                    }
                    {items[activeIndex].provider == 'squiddio' &&
                        <span className="to_right small-text me-2"> © Images are copy-righted by their owners <img width="20px" src={"/assets/logo_noname.png"} /></span>
                    }
                </div>
            }
            {items.length == 0 && (
                <p className="text-white text-center">
                    Sorry, no images available at this location..
                </p>
            )}
            {items.length > 1 && (
                <div className="carouzel-buttons">
                    
                    <button
                        className="button-arrow"
                        onClick={() => {
                            updateIndex(activeIndex - 1)
                        }}
                    >
                        <span className="material-symbols-outlined">
                            <ImgTag icon_name="caret_left" class_name="filter-white" size={30} />
                        </span>{' '}
                    </button>


                    <div className="indicators">
                        {items.map((item, index) => {
                            return (
                                <button
                                    key={index}
                                    className="indicator-buttons"
                                    onClick={() => {
                                        updateIndex(index)
                                    }}
                                >
                                    <span>
                                        <ImgTag
                                            icon_name="circle"
                                            class_name={`${
                                                index === activeIndex
                                                    ? 'filter-green'
                                                    : 'filter-white'
                                            }`}
                                            size={15}
                                        />
                                    </span>
                                </button>
                            )
                        })}
                    </div>
                    <button
                        className="button-arrow"
                        onClick={() => {
                            updateIndex(activeIndex + 1)
                        }}
                    >
                        <span className="">
                            <ImgTag icon_name="caret_right" class_name="filter-white" size={30} />
                        </span>
                    </button>
                </div>
            )}
        </div>
    )
}

export default Images
