// import * as ol from "ol";
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import XYZ from 'ol/source/XYZ'
import Vector from 'ol/source/Vector'
import { fromLonLat, transform } from 'ol/proj'
import GeoJSON from 'ol/format/GeoJSON'
import Overlay from 'ol/Overlay'


import React, { useEffect, useContext, useState } from 'react'
//import { createRoot } from 'react-dom/client'

import { openContextMenu, handleContexMenuEvent } from './ol_context'
import { get_layer, poisStyle } from './ol_layers'
//import { BrowserRouter, useNavigate } from 'react-router-dom'

import PlaceContext from '../store/user-context'

//------------------------------------------------------------------------------------------
const MapBuild = () => {
    // export const mapBuild = (user_longitude, user_latitude, start_zoom, nav) => {

    const context = useContext(PlaceContext)
    console.log(context.place.wtf)
    
    var vp_extent, ll, ur, zoom, lst_evt, place_source //, user_latitude, user_longitude;

    function mapRender(user_longitude, user_latitude, start_zoom) {
        const map = new Map({
            target: 'map',
            renderer: 'canvas',
            view: new View({
                //center: fromLonLat([user_longitude, user_latitude]),
                center: fromLonLat([ -118.27, 33.73]),
                zoom: start_zoom,
                maxZoom: 18,
                minZoom: 3,
            }),
        })

        // choose tile map style
        const esriSatellite = new TileLayer({
            source: new XYZ({
                attributions: [
                    'Not For Navigation.',
                    'Powered by ESRI',
                    'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN,the GIS and the OpenCPN User Communities',
                ],
                attributionsCollapsible: true,
                url:
                    ' https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            }),
            visible: true,
            title: 'EsriSatelliteMap',
        })
        map.addLayer(esriSatellite)

        // open the place pop-up
        const overlayConatinerElement = document.getElementById(
            'overlay-container'
        )
        const overlayLayer = new Overlay({
            element: overlayConatinerElement,
        })
        // map.on('singleclick', e => {
        map.on('singleclick', function(e) {
            // console.log('in map build single click Nav: ', nav)

            //$('.contextMenu').hide();
            overlayLayer.setPosition(undefined)

            map.forEachFeatureAtPixel(e.pixel, function(feature, layer) {
                let clickedCoordinate = e.coordinate
                let id = feature.get('id')

                if (layer.values_.title === 'PlaceLayer') {
                    map.addOverlay(overlayLayer)

                    if (feature.values_.count == null) {
                        // const overlay_div = createRoot(overlayConatinerElement)
                        // overlay_div.render(<PlaceTile place_id={id} navigation={nav} />)
                        // const context = useContext(PlaceContext)
                        // console.log(context.place.wtf)
                    } else {
                        overlayConatinerElement.innerHTML =
                            'Zoom in to see local POIs'
                    }
                }
                overlayLayer.setPosition(clickedCoordinate)
            })
        })

        // context menu http://jsfiddle.net/ddan/eno1x5hk/
        map.getViewport().addEventListener('contextmenu', function(e) {
            e.preventDefault()
            openContextMenu(map, zoom, e)
        })

        map.on('moveend', function(e) {
            lst_evt = e

            vp_extent = e.frameState.extent
            ll = transform(vp_extent.slice(0, 2), 'EPSG:3857', 'EPSG:4326')
            ur = transform(vp_extent.slice(2, 4), 'EPSG:3857', 'EPSG:4326')
            zoom = e.frameState.viewState.zoom

            // ------------------------------ Place layer
            const place_url =
                'api/v1/places/?type=centroid&x1=' +
                ll[0] +
                '&y2=' +
                ur[1] +
                '&x2=' +
                ur[0] +
                '&y1=' +
                ll[1] +
                '&zoom=' +
                zoom

            var layer = get_layer(map, 'PlaceLayer')
            if (!layer) {
                //console.log('!layer');
                place_source = new Vector({
                    url: place_url,
                    format: new GeoJSON(),
                })
                place_source.setProperties({ extent: [ll, ur].flat() })

                place_source.on('change', evt => {
                    if (evt.target.getState() === 'ready') {
                        $('#ajax-loader').hide()
                    }
                })

                var poisLayer = new VectorLayer({
                    source: place_source,
                    visible: true,
                    zindex: 100,
                    style: poisStyle,
                    name: 'PlaceLayer',
                    title: 'PlaceLayer',
                })

                var l = map.addLayer(poisLayer)
            } else {
                place_source.setUrl(place_url)
                place_source.refresh()
                //layer.setZIndex(1);
            }

            //show_layers('PlaceLayer', document.querySelector('input[name="PlaceLayerChbx"]').checked);
            $('#ajax-loader').show()
        })
    }

    useEffect(() => {
        // waits for position to become available before triggering map. If user denies sharing position,
        // default to world view

        // const navigate = useNavigate()
        // console.log ('navigate ', navigate)

        // console.log(nav)

        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        }

        function posAvailable(pos) {
            const crd = pos.coords
            // mapRender(crd.longitude, crd.latitude, 12)
            mapRender(-118.27, 33.73, 12)

        }

        function posUnavailable(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`)
            // mapRender(0.0, 0.0, 0)
            mapRender(-118.27, 33.73)
        }

        navigator.geolocation.getCurrentPosition(
            posAvailable,
            posUnavailable,
            options
        )
    }, [])

    return null
}

export default MapBuild
